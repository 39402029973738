.container {
  width: 92%;
  height: 100%;
  max-height: 100vh;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 82px 1fr 100px;
  justify-content: center;
}
.closeBtn {
  position: absolute;
  top: 94px;
  left: 25px;
  width: 13px;
  height: 13px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../images/close_icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.nameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
}
.name {
  margin: 15px auto 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
  text-align: center;
}
.avatar {
  width: 50px;
  height: 50px;
}
.online {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636363;
  text-align: center;
}
.messagesWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
  max-height: 100%;
  overflow-y: auto;
  padding-left: 20px;
  box-sizing: border-box;
}
.dateSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.date {
  margin: 50px 0 21px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  position: relative;
  text-align: center;
}
.date::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 37%;
  background: #9c9c9c;
  top: 50%;
  left: 0;
}
.date::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 37%;
  background: #9c9c9c;
  top: 50%;
  right: 0;
}

.messagesWrapper::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
/* Handle */
.messagesWrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  opacity: 0.4;
  background: #2f42c2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.text {
  margin: auto;
}
.replyBar {
  margin: 0;
  align-self: center;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 28px;
  min-height: 57px;
}

.input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}
.message {
  margin: 10px 20px 10px 35px;
  max-width: 100%;
  overflow-wrap: anywhere;
}
.placeholder {
  margin: 10px 20px 10px 35px;
  max-width: 100%;
  color: #626262;
}
.sendBtn {
  width: 17px;
  height: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../images/chat_send_icon.svg");
  position: absolute;
  left: 19px;
}

@media screen and (max-width: 1400px) {
  .container {
    grid-template-rows: 73px 1fr 72px;
  }
  .name {
    margin: 11px auto 0;
    font-size: 20px;
    line-height: 26px;
  }
  .avatar {
    width: 40px;
    height: 40px;
  }
  .messagesWrapper {
    padding-left: 12px;
  }
  .dateSection {
    gap: 10px;
  }
  .replyBar {
    min-height: 42px;
    font-size: 16px;
  }
  .sendBtn {
    width: 16px;
    height: 14px;
    left: 13px;
  }
}
@media screen and (max-width: 750px) {
  .messagesWrapper {
    padding-left: 10px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 600px) {
  .container {
    grid-template-rows: 72px 1fr 76px;
    width: 90%;
  }
  .dateSection {
    gap: 20px;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .avatar {
    width: 47px;
    height: 47px;
  }
  .name {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
  }
  .replyBar {
    min-height: 40px;
  }
}
