.myAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0 231px;
  direction: rtl;
}
.myAccount__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.myAccount__title {
  margin: 0 auto 24px;
  color: #2f42c2;
  font-size: 24px;
  font-weight: 600;
}
.myAccount__avatarPlaceholder {
  border-radius: 100%;
  object-fit: cover;
  height: 102px;
  width: 102px;
}
.myAccount__addAvatar {
  border: none;
  outline: none;
  margin-top: -15px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-image: url("../../images/add_avatar_btn.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 100%;
  background-position: center;
}
.myAccount__addAvatar_mobile {
  background-image: url("../../images/add_avatar_btn_purple.svg");
}
.myAccount__accountNumber {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.77;
  text-align: center;
  color: #626262;
  margin: 0 auto 28px;
}
.myAccount__form {
}
.myAccount__list {
  padding: 0;
  margin: 0 auto;
  list-style-type: none;
  display: grid;
  grid-template-columns: 280px 280px;
  grid-auto-rows: max-content;
  width: 750px;
  justify-content: space-between;
  row-gap: 32px;
  align-items: end;
}
.myAccount__label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.77;
  color: #626262;
  margin-right: 5px;
}
.myAccount__input {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.2;
  text-align: right;
  color: #000;
  padding: 8px 17px;
  background: #fff;
  border: none;
  border-radius: 7px;
  width: 280px;
}
.myAccount__input_invalid {
  background: #ffe9e9;
  border: 1px solid #ff0000;
}
.myAccount__input::placeholder {
  color: inherit;
}

/*noinspection CssInvalidPseudoSelector*/
input:-internal-autofill-selected {
  background-color: rgba(255, 255, 255, 1) !important;
}
.myAccount__note {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.2;
  color: #afa7a7;
}
.myAccount__info {
  display: flex;
  justify-content: space-between;
}
.myAccount__changeBtn {
  outline: 0;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  text-decoration: underline;
  color: #626262;
}
.myAccount__changeBtn::before {
  content: "";
  display: inline-block;
  margin-left: 7px;
  width: 14px;
  height: 14px;
  background-image: url("../../images/edit_icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.myAccount__password {
  margin: 19px 0 0;
  min-height: 36px;
  justify-content: flex-start;
}
.myAccount__circle {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 6px;
  background: #626262;
  border-radius: 100%;
}
.myAccount__account {
  margin: 4px 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 2px;
  color: #6a6a6a;
  text-align: start;
  justify-content: flex-start;
}
.myAccount__invalid_message {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #ff0000;
  text-align: center;
  margin: 20px auto 0;
}
.btn {
  margin-top: 43px;
  width: 212px;
  height: 46px;
  font-size: 16px;
  position: relative;
  justify-content: flex-start;
  padding: 0 24px;
}
.btn::before {
  content: "";
  display: inline-block;
  margin-left: 13px;
  width: 14px;
  height: 14px;
  background-image: url("../../images/edit_icon_purple.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.btn_disabled {
  opacity: 0.5;
  cursor: unset;
}
.btn_disabled:hover {
  opacity: 0.5;
  cursor: unset;
  transform: unset;
}

@media screen and (max-width: 1400px) {
  .myAccount {
    padding: 11px 0 40px;
  }
  .myAccount__title {
    margin-bottom: 15px;
  }
  .myAccount__avatarPlaceholder {
    height: 86px;
    width: 86px;
  }
  .myAccount__addAvatar {
    width: 24px;
    height: 24px;
    margin-top: -12px;
  }
  .myAccount__addAvatar:before {
    width: 24px;
    height: 24px;
  }
  .myAccount__accountNumber {
    font-size: 13px;
    margin: 0 auto 22px;
  }
  .myAccount__list {
    grid-template-columns: 231px 231px;
    grid-auto-rows: max-content;
    width: 619px;
    row-gap: 32px;
  }
  .myAccount__label {
    font-size: 13px;
    margin-right: 5px;
  }
  .myAccount__input {
    font-size: 14px;
    border-radius: 6px;
    padding: 6px 15px;
    width: 231px;
  }
  .myAccount__note {
    font-size: 10px;
    line-height: 23px;
  }
  .myAccount__changeBtn {
    font-size: 11px;
  }
  .myAccount__changeBtn::before {
    margin-left: 6px;
    width: 11px;
    height: 11px;
  }
  .myAccount__password {
    margin: 14px 0 0;
    min-height: 29px;
  }
  .myAccount__circle {
    width: 6px;
    height: 6px;
    margin-right: 5px;
  }
  .myAccount__account {
    margin: 14px 0 0;
    font-size: 17px;
  }
  .myAccount__account_placeholder {
    font-size: 14px;
  }
  .btn {
    margin-top: 33px;
    width: 190px;
    height: 42px;
    padding: 0 20px;
  }
  .btn::before {
    margin-left: 10px;
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 840px) {
  .myAccount__list {
    width: calc(100% - 20px);
    column-gap: 40px;
  }
}

@media screen and (max-width: 680px) {
  .myAccount__list {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .myAccount {
    padding: 10px 0 40px;
    width: 315px;
  }
  .myAccount__title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .myAccount__addAvatar {
    margin-top: -15px;
    width: 31px;
    height: 31px;
  }
  .myAccount__avatarPlaceholder {
    width: 115px;
    height: 115px;
  }
  .myAccount__addAvatar:before {
    width: 31px;
    height: 31px;
    background-size: contain;
  }
  .myAccount__accountNumber {
    font-size: 18px;
    margin-top: 10px;
  }
  .myAccount__list {
    gap: 32px;
    width: 100%;
  }
  .myAccount__label {
    font-size: 18px;
    line-height: 31px;
    color: #121759;
  }
  .myAccount__input {
    width: 315px;
    height: 40px;
    font-size: 19px;
    box-sizing: border-box;
  }
  .myAccount__password,
  .myAccount__account {
    background: transparent;
    margin: 0;
    padding: 0;
  }
  .myAccount__changeBtn::before {
    width: 14px;
    height: 14px;
    margin: 0;
  }
  .myAccount__note {
    color: #121759;
    font-size: 14px;
    line-height: 31px;
  }
  .myAccount__circle {
    width: 8px;
    height: 8px;
  }
  .btn {
    margin-top: 50px;
    width: 260px;
    height: 57px;
    padding: 0 20px;
    justify-content: center;
    font-size: 18px;
  }
  .btn::before {
    display: none;
  }
  .btn_disabled {
    background-color: #dbd4f4;
  }
  .myAccount__invalid_message {
    font-size: 14px;
    margin-top: 12px;
  }
}
