.main {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  direction: rtl;
}
.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 493px;
  width: 100%;
  min-height: 780px;
  box-shadow: 0 0 21px -5px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  background-color: #f0f3ff;
}
.welcome__logo {
  width: 152px;
  height: 36px;
  margin: 51px auto 50px;
}
.welcome__pix {
  height: 277px;
  object-position: center;
  object-fit: contain;
  transition: opacity 0.9s ease-in-out;
}
.fadein {
  opacity: 1;
}
.fade {
  opacity: 0;
}
.welcome__pagination {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 106px;
  padding: 0;
  margin: 24px auto;
}
.welcome__page {
  width: 8px;
  height: 8px;
  background-color: #dbd6ef;
  border-radius: 100%;
  cursor: pointer;
}
.welcome__page_active {
  background-color: #5d3cc7;
  width: 12px;
  height: 12px;
}
.welcome__title {
  margin: 25px auto 6px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: #121759;
}
.welcome__text {
  margin: 0 auto;
  max-width: 275px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  color: #606060;
}

.welcome__buttonBar {
  width: 258px;
  margin: 0 auto 52px;
}
.welcome__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 55px;
  border: 1px solid #006cc9;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s;
}

.welcome__btn_type_login {
  background-color: #5d3cc7;
  color: #fff;
  margin: 0 auto 9px;
}
.welcome__btn_type_signin {
  background-color: #fff;
  color: #006cc9;
  border: none;
  box-shadow: 0 3px 27px rgba(0, 0, 0, 0.06);
}
.welcome__forTherapistsNote{
  display: flex;
}
.welcome__forTherapists {
  margin: 0 0 15px 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  display: inline-block;
}
.welcome__forTherapists_link {
  margin: 0 0 0 4px;
  font-family: inherit;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  text-decoration: underline;
  text-underline-offset: 3px;
  color: #000000;
  transition: all 0.3s;
}
.welcome__forTherapists_link:hover {
  opacity: 0.8;
}
.welcome__POC{
  margin-bottom: 25px;
}

@media screen and (max-width: 1400px) {
  .welcome {
    margin-top: 15px;
    max-width: 332px;
    min-height: unset;
    border-radius: 24px;
  }
  .welcome__logo {
    width: 143px;
    height: 31px;
    margin: 43px auto 27px;
  }
  .welcome__pix {
    height: 201px;
  }
  .welcome__title {
    margin: 9px auto 5px;
    font-size: 18px;
  }
  .welcome__text {
    font-size: 16px;
    max-width: 249px;
  }
  .welcome__pagination {
    margin: 17px auto 25px;
  }
  .welcome__page {
    width: 6px;
    height: 6px;
  }
  .welcome__page_active {
    width: 8px;
    height: 8px;
  }
  .welcome__buttonBar {
    width: 175px;
    margin: 0 auto 7px;
  }
  .welcome__btn {
    font-size: 16px;
  }
  .welcome__btn_type_login,
  .welcome__btn_type_signin {
    height: 42px;
    margin: 0 auto 7px;
  }
  .welcome__forTherapists {
    font-size: 12px;
  }
  .welcome__forTherapists_link{
    font-size: 12px;
  }
}
@media screen and (max-width: 1300px) {
  .welcome {
    margin-top: 10px;
    max-width: 300px;
    border-radius: 20px;
  }
  .welcome__logo {
    width: 120px;
    height: 26px;
    margin: 23px auto 20px;
  }
  .welcome__pix {
    height: 180px;
  }
  .welcome__title {
    margin: 9px auto 5px;
    font-size: 17px;
  }
  .welcome__text {
    font-size: 15px;
    max-width: 233px;
  }
  .welcome__pagination {
    margin: 12px auto 20px;
  }
  .welcome__btn_type_login,
  .welcome__btn_type_signin {
    height: 40px;
    margin: 0 auto 7px;
  }
  .welcome__forTherapists {
    font-size: 12px;
  }
  .welcome__forTherapists_link{
    font-size: 12px;
  }
}
@media screen and (max-width: 700px) {
  .welcome {
    width: 284px;
  }
  .welcome__logo {
    width: 121px;
    height: 26px;
    margin-top: 33px;
  }
  .welcome__pix {
    height: 166px;
  }
  .welcome__title {
    font-size: 15px;
  }
  .welcome__text {
    font-size: 14px;
    max-width: 218px;
  }
  .welcome__pagination {
    margin: 15px auto 16px;
  }
  .welcome__page {
    height: 6px;
    width: 6px;
  }
  .welcome__page_active {
    height: 9px;
    width: 9px;
  }
  .welcome__buttonBar {
    width: 148px;
    margin-bottom: 0;
  }
  .welcome__btn {
    height: 34px;
  }
}
