.page {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.section {
  width: 313px;
}
.section__title {
  margin: 25px auto 0;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  color: #2f42c2;
}
.section__btn {
  width: 290px;
  height: 57px;
  margin: 0 auto;
}
.section__btn_search {
  margin: 60px auto 36px;
}
.section__btn_bottomBar {
  margin-top: 36px;
  background: #ffffff;
  border: 1px solid #5d3cc7;
  color: #5d3cc7;
}
.section__text {
  margin: 21px auto 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.11;
  text-align: center;
  color: #626262;
}
.search {
  width: 291px;
}
.search__title {
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
.search__options {
  margin: 50px 0 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.search__option {
  cursor: pointer;
  border: none;
  outline: none;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #5d3cc7;
  background-color: transparent;
}
.search__option_active {
  text-decoration: underline;
}
.search__input {
  width: 100%;
  height: 41px;
  padding: 8px 20px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #5d3cc7;
  border-radius: 8px;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.78;
  color: #626262;
}
.search__variants {
  margin: 0;
  padding: 16px;
  box-sizing: border-box;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #626262;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 8px;
}
.search__variant {
  cursor: pointer;
}
.bottomBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 59px;
  position: relative;
}
.bottomBar__title {
  margin: 0;
  height: 45px;
  width: 45px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #000000;
  z-index: 2;
}
.bottomBar:after {
  top: 20px;
  position: absolute;
  content: "";
  width: 375px;
  height: 1px;
  background-color: #dcdcdc;
}
.therapists {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 82px 0;
  padding: 0;
  list-style-type: none;
  gap: 19px;
}
@media screen and (max-width: 1300px) {
  .search__variants {
    font-size: 16px;
    line-height: 1.5;
  }
  .section__btn_search {
    margin: 48px auto 30px;
  }
  .therapists{
    margin-top: 28px;
  }
}
@media screen and (max-width: 1200px) {
  .section__title {
    margin: 11px auto 0;
  }
  .section__btn {
    font-size: 18px;
  }
  .section__btn_search {
    margin: 15px auto 8px;
  }
  .section__btn_bottomBar {
    margin-top: 10px;
  }
  .section__text {
    margin: 12px 0 13px;
    font-size: 16px;
  }
  .section__text_therapists {
    margin-top: 31px;
  }
  .search__options {
    margin: 10px 0 0;
  }
  .search__option {
    font-size: 16px;
  }
  .search__input {
    font-size: 16px;
  }
  .search__variants {
    padding: 8px 15px;
    font-size: 16px;
  }
  .bottomBar {
    margin-bottom: 31px;
  }
  .bottomBar:after {
    top: 22px;
    position: absolute;
    content: "";
    width: 375px;
    height: 2px;
    background-color: #dcdcdc;
  }
  .therapists {
    margin: 34px 0 55px;
  }
}
@media screen and (max-width: 600px) {
  .section__title {
    margin: 12px auto 16px;
    font-size: 18px;
    line-height: 24px;
  }
  .section__text {
    margin: 0 0 15px;
    max-width: 290px;
    line-height: 1.3;
  }
  .section__text_therapists {
    margin-top: 21px;
  }
  .search__options {
    margin-top: 13px;
  }
  .search__input {
    height: 41px;
  }
  .bottomBar__title,
  .bottomBar:after {
    display: none;
  }
  .section__btn_bottomBar {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #111;
  }
  .therapists {
    margin: 20px 0;
    gap: 10px;
  }
}
