.support {
  width: 324px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 0 25px;
  box-sizing: border-box;
}
.support__title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #00214e;
  margin: 56px 0 13px auto;
}
.support__title_main {
  font-size: 24px;
  font-weight: 600;
  color: #2f42c2;
  margin: 0 auto;
}
.support__text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #606060;
  margin: 0 0 0 auto;
  max-width: 299px;
}
.support__form {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.support__label {
  margin: 7px 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.78;
  color: #121759;
}
.support__input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 41px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  font-size: 18px;
  padding: 10px 20px;
}
.support__inputContainer {
  position: relative;
  margin-bottom: 19px;
  width: 100%;
}
.support__inputContainer:after {
  display: block;
  content: "";
  position: absolute;
  top: 17px;
  left: 19px;
  width: 11px;
  height: 6px;
  cursor: pointer;
  background-image: url("../../images/arrow_down.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.support__themes {
  position: absolute;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  bottom: -154px;
  left: 0;
  width: 100%;
  list-style-type: none;
  margin: 0;
  box-sizing: border-box;
  padding: 12px 18px;
}
.support__theme {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}
.support__textArea {
  box-sizing: border-box;
  width: 100%;
  min-height: 211px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  font-size: 18px;
  padding: 10px 20px;
}
.support__btn {
  margin: 40px auto 0;
  width: 260px;
  height: 57px;
  background: #5d3cc7;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.11;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
}
.support__btn_disabled {
  background-color: #dbd4f4;
}
.support__btn:hover {
  transform: translateY(-3px);
  opacity: 0.8;
}
@media screen and (max-width: 1200px) {
  .support {
    width: 282px;
  }
  .support__title {
    font-size: 15px;
    margin-top: 13px;
  }
  .support__title_main {
    font-size: 24px;
    margin: 0 auto;
  }
  .support__text {
    font-size: 15px;
    line-height: 1.1;
  }
  .support__label {
    font-size: 15px;
    margin: 0 0 3px;
  }
  .support__inputContainer {
    margin-bottom: 8px;
  }
  .support__inputContainer:after {
    top: 18px;
    left: 15px;
  }
  .support__textArea {
    min-height: 187px;
  }
  .support__btn {
    margin-top: 17px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .support {
    width: calc(100% - 78px);
    align-items: center;
    padding-bottom: 20px;
  }
  .support__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    margin: 0 auto 2px;
  }
  .support__title_main {
    margin: 0 auto 24px;
  }
  .support__text {
    display: none;
  }
  .support__form {
    width: 282px;
  }
  .support__label {
    margin: 10px 0 2px;
    font-size: 15px;
    line-height: 2.13;
  }
  .support__inputContainer {
    margin-bottom: 0;
  }
  .support__input {
    width: 100%;
  }
  .support__textArea {
    width: 100%;
  }
  .support__btn {
    margin: 13px auto 0;
  }
  .support__theme {
    font-size: 16px;
  }
}
