.popup {
  width: 493px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 26px;
  background-color: #fff;
  padding: 19px;
  box-sizing: border-box;
  direction: rtl;
  box-shadow: 0 0 16px -4px rgba(0, 0, 0, 0.25);
}
.popup__content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: #ecf2ff;
}
.popup__closeBtn {
  width: 16px;
  height: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../../images/close_icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 13px 12px 0 auto;
}
.popup__title {
  margin: 0 auto 52px;
  color: #2f42c2;
  font-size: 32px;
  font-weight: 700;
}
.popup__img {
  width: 145px;
  height: 145px;
  margin: 31px auto;
}
.popup__text {
  max-width: 292px;
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.1;
  text-align: center;
  color: #000000;
}
.popup__nextBtn {
  margin: 50px auto 27px;
  width: 260px;
}

@media screen and (max-width: 1400px) {
  .popup {
    width: 385px;
    border-radius: 20px;
  }
  .popup__closeBtn {
    width: 12px;
    height: 12px;
    margin: 4px 4px 0 auto;
  }
  .popup__title {
    margin: 8px auto 40px;
    font-size: 25px;
  }
  .popup__img {
    width: 113px;
    height: 113px;
    margin: 20px auto;
  }
  .popup__text {
    margin: 0 auto;
    max-width: 229px;
    font-size: 19px;
    line-height: 1.1;
  }
  .popup__nextBtn {
    margin: 8px auto 5px;
    width: 203px;
    height: 45px;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .popup {
    width: 301px;
    padding: 10px;
    border-radius: 16px;
  }
  .popup__title {
    font-size: 20px;
    margin: 0 auto 33px;
  }
  .popup__text {
    font-size: 15px;
    max-width: 179px;
  }
  .popup__img {
    width: 88px;
    height: 88px;
    margin: 13px auto;
  }
  .popup__nextBtn {
    width: 159px;
    height: 35px;
    font-size: 12px;
    margin-top: 11px;
  }
}
