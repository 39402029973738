.myCalendar {
  width: calc(100% - 80px);
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.myCalendar__title {
  margin: 25px auto 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #2F42C2;
}
.calendarSection {
  margin: 40px auto;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 23px;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.68);
  border-radius: 32px;
  justify-items: center;
  gap: 30px;
}
.tile {
  border-radius: 100%;
}
.highlight {
}
.circleContainer {
  display: flex;
  justify-content: center;
  gap: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.circle {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.circle_big {
  width: 10px;
  height: 10px;
}
.appointmentsContainer {
  width: 90%;
}
.appointmentsList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 70px 0 0;
  padding: 0;
  list-style-type: none;
  gap: 24px;
  width: 100%;
}
.appointmentsLi {
  box-sizing: border-box;
  padding: 24px 26px 24px 35px;
  width: 100%;
  min-height: 89px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
  position: relative;
}
.appointmentsLi:after {
  display: block;
  cursor: pointer;
  content: "";
  width: 6px;
  height: 11px;
  background-image: url("../../images/arrow_left.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 20px;
  top: 45%;
}
.time {
  display: flex;
  align-items: center;
}
.duration {
  margin: 0 14px 0 0;
  direction: ltr;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #626262;
}
.name {
  margin: 0 24px 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}

@media screen and (max-width: 1200px) {
  .myCalendar {
    width: calc(100% - 108px);
  }
  .myCalendar__title {
    margin: 11px auto 10px;
    font-size: 24px;
  }
  .calendarSection {
    gap: 20px;
  }
  .appointmentsContainer{
    align-self: flex-start;
    width: 100%;
  }
  .appointmentsList {
    gap: 15px;
  }
  .appointmentsLi {
    padding: 13px 20px 13px 28px;
    /*width: 280px;*/
    min-height: 70px;
  }
  .name {
    font-size: 16px;
  }
}
@media screen and (max-width: 800px) {
  .myCalendar__title {
    margin: 10px auto 13px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  .calendarSection {
    display: flex;
    flex-direction: column;
    padding: 18px 15px;
    margin-top: 0;
  }
  .myCalendar{
    width: 100%;
  }
  .appointmentsList {
    margin: 0;
  }
}
