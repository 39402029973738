.myDay {
  margin: 13px auto 50px;
  display: flex;
  flex-direction: column;
}
.myDay__title {
  margin: 0 auto 19px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: #2f42c2;
}
.myDay__section {
  position: relative;
  width: 592px;
  background: #ffffff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  margin: 33px 0 7px;
  color: #2f42c2;
}
.nextBtn{
  width: 9px;
  height: 17px;
  position: absolute;
  top: 45%;
  left: -40px;
  background-image: url("../../images/arrow_left.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.myDay__sectionTitle {
  position: absolute;
  margin: 0;
  top: -20px;
  left: calc((100% - 92px) / 2);
  width: fit-content;
  height: 41px;
  background: #006cc9;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 25px;
  color: #ffffff;
  padding: 10px 18px;
  box-sizing: border-box;
}
.myDay__swiper {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  list-style-type: none;
}
.myDay__sectionCard {
  margin: 0;
  padding: 19px 25px 20px 33px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 157px;
}
.myDay__sectionCard_line {
}
.myDay__sectionCard_line:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 20%;
  width: 1px;
  height: 60%;
  background-color: #2f42c2;
}
.myDay__sectionArrowBtn {
  height: 16px;
  width: 8px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  background-image: url("../../images/arrow_left_blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.myDay__sectionText {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  text-align: right;
  color: #2f42c2;
  max-width: 212px;
}
.myDay__sectionText_centered {
  text-align: center;
  margin-bottom: 10px;
}
.myDay__link {
  cursor: pointer;
  text-decoration: underline;
}
.myDay__link:hover {
  opacity: 0.8;
}
.notifications {
  display: flex;
  gap: 14px;
  box-sizing: border-box;
  padding: 29px 19px 23px;
}
.notifications__venchy {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.notifications__img {
  width: 56px;
  height: 56px;
}
.notifications__name {
  margin: 0;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}
.notifications__date {
  margin: 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
}
.notifications__text {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  flex: 0 0 100%;
}

@media screen and (max-width: 1400px) {
  .myDay {
    margin: 14px auto 40px;
  }
  .myDay__title {
    margin: 0 auto 21px;
    font-size: 24px;
  }
  .myDay__section {
    width: 507px;
    margin-top: 40px;
    border-radius: 20px;
  }
  .myDay__sectionTitle {
    top: -17px;
    height: 35px;
    font-size: 16px;
    padding: 7px 15px;
    left: calc((100% - 77px) / 2);
  }
  .myDay__sectionCard {
    padding: 13px 17px 15px 25px;
    min-height: 135px;
  }

  .myDay__sectionText {
    font-size: 16px;
    line-height: 21px;
  }
  .myDay__sectionText_small {
    font-size: 14px;
    margin: 12px 0 0;
  }

  .myDay__sectionArrowBtn {
    height: 12px;
  }

  .notifications {
    padding: 25px 19px 9px;
  }

  .notifications__text {
    font-size: 15px;
    line-height: 20px;
  }
  .myDay__sectionContent {
    margin-right: 25px;
  }
}

@media screen and (max-width: 800px) {
  .myDay {
    width: calc(100% - 50px);
  }
  .myDay__section {
    width: unset;
    box-shadow: 0 2px 21px rgba(0, 0, 0, 0.07);
    margin-top: 62px;
    margin-bottom: 13px;
  }
  .myDay__section:first-of-type {
    margin-top: 40px;
  }
  .myDay__sectionTitle {
    top: -23px;
    height: 47px;
    padding: 12px 20px;
    left: calc((100% - 88px) / 2);
    font-size: 16px;
  }
  .myDay__sectionCard {
    padding: 23px 19px 15px 25px;
    min-height: 123px;
    gap: 17px;
    width: 100%;
  }
  .myDay__sectionCard_centered {
    justify-content: center;
  }
  .myDay__sectionText_small {
    margin: 12px auto 0;
  }
  .myDay__sectionCard:last-of-type {
    padding-top: 15px;
  }
  .myDay__sectionText {
    font-size: 16px;
    line-height: 21px;
  }
  .myDay__sectionArrowBtn {
    height: 10px;
    width: 5px;
  }
  .myDay__sectionContent {
    margin-right: unset;
  }
}
@media screen and (max-width: 700px) {
  .myDay {
    width: 301px;
    margin-top: 11px;
  }
  .myDay__title {
    margin-bottom: 0;
  }
  .myDay__swiper {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .myDay__sectionCard:first-child:after {
    width: 248px;
    height: 1px;
    bottom: 0;
    top: unset;
    left: 8%;
  }
  .nextBtn {
    display: none;
  }
  .notifications {
    flex-direction: column;
    padding-top: 34px;
    gap: 8px;
  }
  .notifications__venchy {
    flex-direction: row;
    justify-content: center;
  }
  .notifications__img {
    width: 38px;
    height: 38px;
  }
}
