.popup {
  width: 495px;
  min-height: 539px;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
}
.popup__title {
  margin: 16px auto 59px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  color: #2f42c2;
}
.popup__img {
  width: 145px;
  height: 145px;
  margin: 0 auto 36px;
}
.popup__text {
  margin: 0 auto 58px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  color: #000000;
  max-width: 300px;
}
.popup__nextBtn {
  margin: 0 auto 27px;
  width: 260px;
  height: 57px;
  font-size: 20px;
}

@media screen and (max-width: 1200px) {
  .popup {
    width: 405px;
    min-height: 442px;
  }
  .popup__title {
    margin: 14px auto 51px;
    font-size: 26px;
  }
  .popup__img {
    width: 119px;
    height: 119px;
    margin: 0 auto 30px;
  }
  .popup__text {
    margin: 0 auto 47px;
    font-size: 20px;
    max-width: 247px;
  }
  .popup__nextBtn {
    margin: 0 auto 22px;
    width: 214px;
    height: 47px;
    font-size: 16px;
    cursor: pointer;
  }
}
@media screen and (max-width: 560px) {
  .popup {
    width: 296px;
    min-height: 323px;
  }
  .popup__title {
    margin-bottom: 37px;
    font-size: 20px;
  }
  .popup__text {
    font-size: 14px;
    max-width: 180px;
    margin-bottom: 34px;
  }
  .popup__img {
    width: 87px;
    height: 86px;
    margin: 0 auto 25px;
  }
  .popup__nextBtn {
    width: 156px;
    height: 34px;
    font-size: 12px;
  }
}
