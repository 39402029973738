.therapist {
  width: 100%;
  padding: 22px 0;
  display: flex;
  flex-direction: column;
  gap: 43px;
  position: relative;
  align-items: center;
}
.infoContainer {
  padding: 35px 35px 20px;
  display: flex;
  gap: 59px;
  background: rgba(255, 255, 255, 0.65);
  border-radius: 32px;
}
.therapist__closeBtn {
  width: 260px;
  height: 57px;
  outline: 0;
  background-color: #fff;
  color: #2f42c2;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  border: 1px solid #2f42c2;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  width: 366px;
  min-height: 400px;
}
.card__avatar {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin: 0 auto 26px;
}
.card__name {
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #121759;
}
.card__status {
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  color: #111111;
}
.card__tag {
  margin: 25px auto 60px;
  background: #5d3cc7;
  border-radius: 100px;
  padding: 0 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
}
.card__connectionBar {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  list-style-type: none;
}
.card__connectionBarLi {
  cursor: pointer;
  width: 100px;
  height: 72px;
  background-color: #f8f8f8;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
}
.card__connectionBarLi:hover,
.card__btn:hover,
.therapist__closeBtn:hover,
.therapist__moreBtn:hover {
  transform: translateY(-3px);
  opacity: 0.8;
}
.card__connectionBarLi_active {
  background-color: #e0daf4;
}
.card__connectionBarLi_chat {
  background-image: url("../../images/chat_btn.png");
}
.card__connectionBarLi_phone {
  background-image: url("../../images/phone_btn.png");
}
.card__connectionBarLi_video {
  background-image: url("../../images/video_btn.png");
}
.card__btn {
  border: none;
  outline: none;
  width: 260px;
  height: 57px;
  background: #5d3cc7;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.11;
  text-align: center;
  color: #ffffff;
  margin: 30px auto;
  cursor: pointer;
}
.therapist__info {
  display: flex;
  flex-direction: column;
}
.therapist__video {
  margin: 0 auto;
  height: 234px;
  width: 401px;
  cursor: pointer;
}
.therapist__about {
  margin-top: 20px;
  border-radius: 32px;
  box-sizing: border-box;
  padding: 15px 16px 0;
}
.therapist__aboutTitle {
  margin: 0 auto;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

/*noinspection CssUnknownProperty*/
.therapist__text {
  display: -webkit-box;
  margin: 7px 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.39;
  text-align: right;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  max-height: 175px;
}

/*noinspection CssUnknownProperty*/
.therapist__text_full {
  -webkit-line-clamp: unset;
}
.therapist__moreBtn {
  display: block;
  margin: 7px auto 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  text-decoration: underline;
}

@media screen and (max-width: 1200px) {
  .card {
    width: 260px;
    min-height: unset;
  }
  .infoContainer {
    box-sizing: border-box;
    gap: 20px;
    padding: 12px 29px;
    width: calc(100% - 68px);
  }
  .card__avatar {
    width: 80px;
    height: 80px;
    margin-bottom: 5px;
  }
  .card__name,
  .card__status {
    font-size: 18px;
    line-height: 1.7;
  }
  .card__tag {
    margin: 4px auto 44px;
  }
  .card__connectionBar {
    gap: 10px;
  }
  .card__connectionBarLi {
    width: 80px;
  }
  .card__btn {
    margin: 17px 0;
  }
  .therapist__video {
    width: 236px;
    height: 138px;
    margin-bottom: 8px;
  }
  .therapist__about {
    margin: 0;
    padding: 7px 13px;
  }
  .therapist__text {
    margin: 0;
    font-size: 18px;
    line-height: 25px;
  }
  .therapist__aboutTitle {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .therapist__moreBtn {
    margin: 0 auto;
  }
}
@media screen and (max-width: 850px) {
  .infoContainer {
    flex-direction: column;
    align-items: center;
    width: 400px;
  }
}
@media screen and (max-width: 600px) {
  .therapist {
    margin: 0;
    padding: 0;
    gap: 20px;
  }
  .infoContainer {
    width: 100%;
    padding: 0;
  }
  .card {
    width: 277px;
    min-height: 298px;
  }
  .card__avatar {
    width: 50px;
    height: 50px;
    margin: 24px auto 10px;
  }
  .card__name {
    font-size: 16px;
    line-height: 24px;
  }
  .card__status {
    font-weight: 400;
    font-size: 16px;
  }
  .card__tag {
    margin: 16px auto 38px;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
  }
  .card__connectionBar {
    gap: unset;
    justify-content: space-between;
  }
  .card__connectionBarLi {
    border: 1px solid #5D3CC7;
    height: 60px;
    width: 82px;
    background-size: 50px;
  }
  .card__btn {
    width: 204px;
    height: 48px;
    font-size: 16px;
    margin: 20px auto 0;
  }
  .card__tag{
    margin-bottom: 19px;
  }
  .therapist__video {
    margin: 0 auto 10px;
    height: 114px;
    width: 195px;
  }
  .therapist__about {
    margin-top: 0;
    padding-bottom: 15px;
  }
  .therapist__aboutTitle {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 13px;
  }
  .therapist__text {
    font-size: 16px;
  }
  .therapist__moreBtn {
    font-size: 16px;
    line-height: 20px;
  }
  .therapist__closeBtn {
    font-size: 16px;
    margin-bottom: 45px;
  }
}
