.mySettings {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  box-sizing: border-box;
}
.mySettings__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.31;
  color: #2f42c2;
  margin: 0 auto 42px;
}
.mySettings__list {
  display: grid;
  max-width: 778px;
  grid-template-columns: 1fr 1fr;
  column-gap: 42px;
  row-gap: 42px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.mySettings__btn {
  width: 368px;
  height: 74px;
  background: #ffffff;
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.07);
  border-radius: 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: right;
  color: #2f42c2;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 25px;
  position: relative;
}
.mySettings__btn:after {
  content: "";
  background-image: url("../../images/arrow_left_blue.svg");
  position: absolute;
  left: 37px;
  top: 39%;
  width: 9px;
  height: 15px;
}

@media screen and (max-width: 1400px) {
  .mySettings {
    padding-top: 11px;
  }
  .mySettings__list {
    column-gap: 35px;
    row-gap: 30px;
  }
  .mySettings__btn {
    width: 305px;
    height: 61px;
    border-radius: 20px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
  .mySettings__btn:after {
    left: 29px;
  }
}

@media screen and (max-width: 800px) {
  .mySettings__btn {
    width: 270px;
  }
}
@media screen and (max-width: 740px) {
  .mySettings__title {
    margin-bottom: 16px;
    font-size: 18px;
  }
  .mySettings__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
  }
  .mySettings__btn {
    width: 277px;
    height: 56px;
  }
  .mySettings__btn::after {
    top: 35%;
    left: 25px;
    width: 7px;
  }
}
