.main {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  direction: rtl;
}
.password {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 493px;
  width: 100%;
  min-height: 780px;
  box-shadow: 0 0 21px -5px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  background-color: #f0f3ff;
  position: relative;
}
.password__logo {
  width: 152px;
  height: 36px;
  margin: 51px auto 50px;
}
.password__pix {
  height: 277px;
  object-position: center;
  object-fit: contain;
}
.password__title {
  margin: 25px auto 6px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: #121759;
}
.password__text {
  margin: 0 auto;
  max-width: 300px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  color: #606060;
}
.password__input {
  padding: 0 15px;
  width: 100%;
  height: 55px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  font-family: inherit;
  font-size: 17px;
  box-sizing: border-box;
  margin: 15px 0;
}
.password__input::placeholder {
  font-size: 20px;
  color: #ababab;
}
.password__input_invalid {
  background: #ffe9e9;
  border: 1px solid #ff0000;
}
.password__buttonBar {
  width: 290px;
  margin: 0 auto 52px;
}
.password__btn {
  width: 100%;
  height: 55px;
}
.password__btn_disabled {
  background-color: #dbd4f4;
}

@media screen and (max-width: 1400px) {
  .password {
    margin-top: 15px;
    max-width: 332px;
    min-height: unset;
    border-radius: 24px;
  }
  .password__logo {
    width: 143px;
    height: 31px;
    margin: 43px auto 27px;
  }
  .password__pix {
    height: 201px;
  }
  .password__title {
    margin: 9px auto 5px;
    font-size: 18px;
  }
  .password__text {
    font-size: 16px;
    max-width: 249px;
  }
  .password__input {
    height: 48px;
  }
  .password__input::placeholder {
    font-size: 14px;
    color: #ababab;
  }
  .password__buttonBar {
    width: 249px;
    margin: 0 auto 7px;
  }
  .password__btn {
    height: 48px;
    font-size: 16px;
  }
}
@media screen and (max-width: 700px) {
  .password {
    width: 284px;
  }
  .password__logo {
    width: 121px;
    height: 26px;
    margin-top: 33px;
  }
  .password__pix {
    height: 166px;
  }
  .password__title {
    font-size: 15px;
  }
  .password__text {
    font-size: 14px;
    max-width: 218px;
  }
  .password__buttonBar {
    width: 218px;
    margin-bottom: 0;
  }
  .password__input {
    font-size: 16px;
    height: 40px;
  }
  .password__btn {
    height: 40px;
    border-radius: 8px;
  }
}
