.section {
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.section__title {
  margin: 0 auto 64px;
  color: #2f42c2;
  font-size: 24px;
  font-weight: 600;
}
.text {
  margin: 14px auto 61px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  color: #626262;
  text-align: center;
}

@media screen and (max-width: 1400px) {
  .section {
    padding: 13px 0;
  }
  .section__title {
    margin-bottom: 30px;
  }
  .text {
    font-size: 16px;
    margin: 15px auto 64px;
  }
  .btn {
    width: 260px;
    height: 57px;
    font-size: 18px;
  }
}
@media screen and (max-width: 1300px) {
  .section__img {
    width: 244px;
    height: 148px;
  }
  .btn {
    width: 242px;
    height: 53px;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .section {
    padding-top: 9px;
  }
  .section__title {
    font-size: 18px;
    margin-bottom: 35px;
  }
  .text {
    margin: 23px auto;
  }
  .btn {
    width: 242px;
    height: 53px;
  }
}
