.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 493px;
  width: 100%;
  min-height: 780px;
  background-color: #F0F3FF;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 26px;
  z-index: 2;
  position: relative;
}
.signin__title {
  margin: 20px 0 auto;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.3;
  color: #006cc9;
}
.signin__text {
  margin: 20px auto 75px;
  font-weight: 400;
  font-size: 24px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  max-width: calc(100% - 50px);
}
.signin__text_small{
  font-size: 18px;
  line-height: 1.3;
}
.signin__list {
  box-sizing: border-box;
  width: 100%;
  list-style-type: none;
  padding: 0 57px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.signin__listItem {
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  min-height: 92px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  position: relative;
  color: #000;
  text-decoration: none;
}

.signin__icon {
  position: absolute;
  width: 45px;
  height: 45px;
}

.signin__listText {
  margin: 0;
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  line-height: 31px;
}

.signin__conditions {
  max-width: 380px;
  margin: 93px auto 23px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #606060;
}
.signin__terms {
  display: inline;
}
.signin__link {
  display: inline;
  margin: 0 3px;
  color: inherit;
}
.signin__link:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1400px) {
  .signin {
    margin-top: 0;
    max-width: 325px;
    min-height: unset;
    border-radius: 15px;
  }
  .signin__title {
    font-size: 27px;
  }
  .signin__text {
    margin: 9px auto 42px;
    font-size: 17px;
  }
  .signin__text_small{
    font-size: 14px;
  }
  .signin__list {
    padding: 0 36px;
    gap: 24px;
  }
  .signin__listItem {
    border-radius: 9px;
    min-height: 72px;
  }
  .signin__icon {
    width: 34px;
    height: 34px;
  }
  .signin__listText {
    font-size: 18px;
  }
  .signin__conditions {
    max-width: 290px;
    margin: 75px auto 13px;
    font-size: 13px;
    line-height: 14px;
  }
}

@media screen and (max-width: 1300px) {
  .signin__title{
    font-size: 25px;
    line-height: 1.1;
  }
  .signin__text{
    margin-bottom: 32px;
  }
  .signin__listItem{
    min-height: 64px;
  }
  .signin__conditions{
    margin-top: 44px;
    max-width: 250px;
  }
}
@media screen and (max-width: 600px) {
  .signin {
    width: 284px;
    height: unset;
    padding-bottom: 15px;
  }
  .signin__arrow{
    display: none;
  }
  .signin__title{
    font-size: 24px;
    line-height: 31px;
    margin: 32px auto 15px;
  }
  .signin__text {
    margin: 0 auto 42px;
    font-size: 16px;
  }
  .signin__text_small{
    font-size: 12px;
  }
  .signin__list{
    padding: 0 18px;
    gap: 19px;
  }
  .signin__listItem {
    min-height: 60px;
    padding: 0 12px;
  }
  .signin__conditions {
    margin: 50px auto 6px;
    max-width: 250px;
    font-size: 12px;
    line-height: 1;
  }
}
