.section {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 25px;
  box-sizing: border-box;
  position: relative;
  max-width: 1200px;
}
.sectionTitle {
  margin: 16px auto 25px;
  color: #2f42c2;
  font-size: 24px;
  font-weight: 600;
}
.reports {
  list-style-type: none;
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 20px;
}
.reportsBtn {
  width: 368px;
  height: 74px;
  background: #ffffff;
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.07);
  border-radius: 25px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  text-align: right;
  color: #2f42c2;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 25px;
  position: relative;
}
.reportsBtn:after,
.reportCard:after {
  content: "";
  background-image: url("../../images/arrow_left_blue.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 37px;
  top: 39%;
  width: 9px;
  height: 15px;
}
.reportCard:after {
  background-image: url("../../images/arrow_left_gray.svg");
  top: 46%;
}
.reportsContent {
  width: 80%;
}
.reportsList {
  list-style-type: none;
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.reportCard {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background: #fff;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  padding: 20px 35px;
  cursor: pointer;
}
.reportCard__date {
  margin: 0 0 10px;
  color: #606060;
  font-size: 18px;
  line-height: 1.2;
}
.reportCard__title {
  margin: 0 0 4px;
  color: #111;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
}
.reportsCreateBtn{
  margin: 20px auto;
}

@media screen and (max-width: 1400px) {
  .section {
    padding-top: 11px;
  }
  .reports {
    column-gap: 35px;
    row-gap: 24px;
  }
  .reportsBtn {
    width: 305px;
    height: 61px;
    border-radius: 20px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    padding: 0 14px;
  }
  .reportsBtn:after {
    left: 29px;
  }
}

@media screen and (max-width: 1300px) {
  .sectionTitle {
    margin-top: 8px;
  }
  .reportsList {
    gap: 14px;
  }
  .reportCard {
    padding: 16px 32px;
  }
  .reportCard__date {
    margin: 0 0 8px;
    font-size: 16px;
  }
  .reportCard__title {
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) {
  .reportsList {
    gap: 10px;
  }
  .reportCard {
    padding: 8px 20px;
  }
  .reportCard__date {
    margin: 0 0 6px;
    font-size: 14px;
  }
  .reportCard__title {
    font-size: 16px;
  }
  .reportsCreateBtn{
    width: 214px;
    height: 46px;
    font-size: 18px;
  }
}
@media screen and (max-width: 850px) {
  .reportsBtn {
    width: 270px;
  }
}
@media screen and (max-width: 740px) {
  .sectionTitle {
    font-size: 18px;
  }
  .reports {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
  }
  .reportsBtn {
    width: 277px;
    height: 56px;
  }
  .reportsBtn::after {
    top: 35%;
    left: 25px;
    width: 7px;
  }
  .reportsContent{
    width: 100%;
  }
}
