.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 879px;
  width: calc(100% - 262px);
  padding: 26px 0;
}
.section__title {
  margin: 0 auto 22px;
  color: #2f42c2;
  font-size: 24px;
  font-weight: 600;
}
.btn {
  height: 23px;
  width: 71px;
  border-radius: 7px;
  padding: 4px 27px 4px 27px;
  align-self: flex-end;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #000000;
  background-color: transparent;
  border: 1px solid #5d3cc7;
  cursor: pointer;
}
.select {
  box-sizing: border-box;
  display: none;
  grid-template-columns: 14px 1fr;
  position: absolute;
  top: 96px;
  left: 0;
  height: 159px;
  width: 210px;
  background: #ffffff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 18px 10px;
  column-gap: 10px;
  z-index: 3;
}
.select_visible {
  display: grid;
}
.radio {
  margin: auto;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.label {
  margin: auto 0;
  font-size: 16px;
  line-height: 1;
  color: #000000;
  cursor: pointer;
}
.invoicesList {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 57px;
}
.invoice {
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 3px 27px rgba(0, 0, 0, 0.07);
  border-radius: 9px;
  padding: 10px 35px;
  position: relative;
}
.invoice:before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background: #d9d9d9;
  right: 13px;
  top: 49%;
}
.invoice:after {
  position: absolute;
  content: "";
  width: 23px;
  height: 13px;
  background-image: url("../../images/arrow_down.svg");
  background-repeat: no-repeat;
  background-size: contain;
  left: 31px;
  top: 34%;
  cursor: pointer;
}
.invoiceTitle {
  height: 44px;
  width: 206px;
  border-radius: 4px;
  background: #2f42c2;
  border: 1px solid #f4f4e8;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  margin: -36px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.date {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  text-align: right;
  margin: 0;
  color: #626262;
}
.month {
  margin: 9px 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 1;
  text-align: right;
  color: #00214e;
}
.sum {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8;
  display: flex;
  align-items: center;
  text-align: right;
  color: #626262;
}

@media screen and (max-width: 1400px) {
  .section {
    width: calc(100% - 150px);
    padding-top: 11px;
  }
  .select {
    top: 80px;
  }
  .invoicesList {
    margin: 22px 0;
  }
  .invoice {
    width: unset;
    padding: 8px 27px;
  }
  .invoice:before {
    width: 7px;
    height: 7px;
    right: 9px;
    top: 45%;
  }
  .invoiceTitle {
    width: 172px;
    height: 36px;
    font-size: 18px;
  }
  .month {
    margin-top: 5px;
    font-size: 19px;
  }
  .sum {
    line-height: 1.5;
  }
}

@media screen and (max-width: 700px) {
  .section {
    width: calc(100% - 48px);
  }
  .section__title {
    font-size: 18px;
  }
  .invoice {
    padding-top: 12px;
    height: 69px;
  }
  .invoiceTitle {
    display: inline-block;
    width: fit-content;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #111;
    font-size: 17px;
    height: 17px;
    font-weight: 600;
    margin-left: 5px;
  }
  .month {
    display: inline-block;
    color: #111;
    font-size: 17px;
  }
  .sum {
    line-height: 1;
  }
  .invoice:after {
    width: 5px;
    height: 9px;
    background-image: url("../../images/arrow_left.svg");
    left: 18px;
    top: 45%;
  }
  .invoice:before {
    display: none;
  }
  .invoicesList {
    gap: 10px;
  }
  .date {
    display: none;
  }
}
