.section {
  width: calc(100% - 180px);
  max-width: 1200px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
}
.section__title {
  margin: 16px auto 60px;
  color: #2f42c2;
  font-size: 24px;
  font-weight: 600;
}
.noGoals {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
}
.noGoals__text {
  margin: 20px auto 55px;
  font-size: 18px;
}
.noGoals__btn {
  width: 260px;
  font-size: 18px;
}
.setGoals {
  width: 100%;
}
.goals {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
}
.goals__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 20px;
}
.goals__li {
  width: 400px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background-color: rgba(255, 255, 255, 0.65);
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  padding: 25px;
}
.goals__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
.goals__title {
  margin: 0;
  color: #111;
  line-height: 1;
  font-size: 22px;
}
.goals__editBtn {
  height: 20px;
  width: 20px;
  border: none;
  outline: none;
  background-image: url("../../images/edit_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: transparent;
  cursor: pointer;
}
.goals__progressBar {
  width: 100%;
  height: 12px;
  border-radius: 10px;
  background-color: #dbd6ef;
}
.goals__progress {
  width: 0;
  height: 12px;
  border-radius: 0 10px 10px 0;
  background-color: #5d3cc7;
}
.goals__progressNumber {
  margin: 6px auto 6px 0;
  color: #5d3cc7;
  font-size: 22px;
  line-height: 1;
}
.goals__comments {
  display: grid;
  grid-template-columns: 54px 1fr;
  gap: 20px;
  align-items: center;
  margin-top: 25px;
}
.goals__avatar {
  width: 100%;
}
.goals__text {
  margin: 0;
  color: #111;
  font-size: 18px;
  line-height: 1.1;
}
.goals__text_bold {
  font-weight: 600;
  margin-left: 5px;
}
@media screen and (max-width: 1300px) {
  .section {
    padding-bottom: 20px;
  }
  .section__title {
    margin-bottom: 20px;
  }
  .noGoals__text {
    font-size: 16px;
  }
  .noGoals__btn {
    width: 200px;
    height: 48px;
    font-size: 16px;
  }
  .goals__li {
    width: 250px;
    padding: 20px 15px;
  }
  .goals__title,
  .goals__progressNumber {
    font-size: 17px;
  }
  .goals__header {
    margin-bottom: 24px;
  }
  .goals__editBtn {
    height: 14px;
  }
  .goals__comments {
    grid-template-columns: 45px 1fr;
    gap: 10px;
    margin-top: 20px;
  }
  .goals__text {
    font-size: 15px;
  }
  @media screen and (max-width: 1024px) {
    .section{
      width: calc(100% - 100px);
    }
    .goals__li {
      width: 236px;
      padding: 20px 14px;
    }
  }
}
@media screen and (max-width: 800px) {
  .section {
    width: 95%;
  }
  .section__title {
    font-size: 18px;
  }
  .noGoals__btn {
    width: 270px;
  }
}
@media screen and (max-width: 600px){
  .goals__li{
    width: 95%;
  }
}