.react-calendar {
  width: 372px;
  max-width: 100%;
  border: none;
  line-height: 1.125em;
  background-color: transparent;
  font-family: "Assistant", sans-serif;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-family: inherit;
  position: relative;
  height: 100%;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  font-style: normal;
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  color: #222b45;
  font-family: inherit;
}
.react-calendar__navigation button:disabled {
  opacity: 0.5;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  gap: 1px;
}
.react-calendar__month-view__days{
  display: grid!important;
  grid-template-columns: repeat(7, 53px);
  grid-auto-rows: 55px;
  row-gap: 5px;
  gap: 1px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  text-align: right;
  color: #8f9bb3;
  margin-bottom: 10px;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  /*color: #d10000;*/
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  font-family: inherit;
  color: #222b45;
  max-width: 100%;
  display: flex;
  padding: 12px 7px;
  background: none;
  text-align: center;
  line-height: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.react-calendar__tile:disabled {
  font-family: inherit;
  background-image: url("../images/circle_grey.svg");
  background-size: 44px 44px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  padding-top: 12px;
  opacity: .5;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /*background-color: #e6e6e6;*/
  transform: translateY(-3px);
  opacity: 0.8;
}

.react-calendar__tile--now {
  /*background: #5D3CC7;*/
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  /*background: #5D3CC7;*/
  transform: translateY(-3px);
  opacity: 0.8;
}

.react-calendar__tile--hasActive {
  background: #5d3cc7;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active  {
  background-image: url("../images/circle_bg_violet.svg");
  background-size: 44px 44px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  color: #fff;
  padding-top: 12px;
}

/*.react-calendar button abbr {*/
/*  background: #5d3cc7;*/
/*}*/

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  /*background: #5D3CC7;*/
}

.react-calendar--selectRange .react-calendar__tile--hover {
  /*background-color: #e6e6e6;*/
}

@media screen and (max-width: 1050px) {
  .react-calendar__navigation button {
    font-size: 20px;
    line-height: 16px;
  }
}

@media screen and (max-width: 780px) {
  .react-calendar {
    width: 300px;
  }
  .react-calendar__navigation{
    margin-bottom: 0.5em;
  }
  .react-calendar__navigation button {
    font-size: 18px;
    line-height: 16px;
  }
  .react-calendar__month-view__weekdays__weekday{
    font-size: 14px;
  }
  .react-calendar__month-view__days{
    grid-template-columns: repeat(7, 40px);
    grid-auto-rows: 47px;
    gap: 4px;
  }
  .react-calendar__tile:disabled {
    background-size: 40px 40px;
  }
  .react-calendar__tile--active  {
    background-size: 37px 37px;
    padding-top: 11px;
  }

}