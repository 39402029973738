.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section__title {
  margin: 25px auto 43px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: #2f42c2;
}
.section__categories {
  margin: 0 auto 67px;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 394px 394px;
  grid-template-rows: repeat(3, 1fr);
  gap: 45px;
}
.section__category {
  text-decoration: none;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0 3px 27px rgba(0, 0, 0, 0.07);
  border-radius: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 26px 41px 28px 42px;
  color: #2f42c2;
}
.section__sectionArrowBtn {
  height: 16px;
  width: 8px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  background-image: url("../../images/arrow_left_blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.section__text {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.3;
}
.section__icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-left: 16px;
}
.section__sectionName{
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1400px) {
  .section__title {
    margin: 11px auto 40px;
  }
  .section__categories {
    grid-template-columns: 304px 304px;
    gap: 30px;
    margin-bottom: 40px;
  }
  .section__category {
    box-shadow: 0 2px 21px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    padding: 21px 19px 21px 26px;
  }
  .section__sectionArrowBtn {
    height: 11px;
    width: 5px;
  }
  .section__text {
    font-size: 15px;
  }
  .section__icon {
    width: 18px;
    height: 18px;
    margin-left: 13px;
  }
}
@media screen and (max-width: 840px) {
  .section {
    width: 95%;
  }
  .section__categories {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 660px) {
  .section {
    width: calc(100% - 82px);
  }
  .section__title {
    margin: 10px auto 16px;
    font-size: 18px;
  }
  .section__categories {
    grid-template-columns: 1fr;
  }
  .section__category {
    padding: 18px 19px 18px 26px;
  }
  .section__text {
    font-size: 16px;
  }
}
