.popup {
  width: 493px;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
  background: #f0f3ff;
}
.popup__closeBtn {
  width: 16px;
  height: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../../images/close_icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 28px 28px 0 auto;
}
.popup__title {
  margin: 17px auto 70px;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  color: #2f42c2;
}
.popup__title_small{
  margin: 0 auto 20px;
  font-size: 22px;
  max-width: 80%;
}
/*.popup__text {*/
/*  color: #121759;*/
/*  font-size: 19px;*/
/*  margin: 0 auto 10px;*/
/*  max-width: 80%;*/
/*}*/

.popup__text {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  max-width: 450px;
  text-align: center;
  color: #000000;
}
.popup__nextBtn {
  margin: 77px auto 17px;
  width: 260px;
}
.popup__nextBtn_white {
  margin: 0 auto 30px;
  border: 1px solid #5d3cc7;
  background-color: transparent;
  color: #5d3cc7;
}

@media screen and (max-width: 1400px) {
  .popup {
    width: 385px;
  }
  .popup__closeBtn {
    width: 12px;
    height: 12px;
    margin: 19px 19px 0 auto;
  }
  .popup__title {
    font-size: 25px;
    margin: 12px auto 40px;
  }
  .popup__title_small{
    font-size: 18px;
    margin-bottom: 10px;
    max-width: 87%;
  }
  .popup__text {
    font-size: 18px;
    max-width: 90%;
    line-height: 1.4;
  }
  .popup__nextBtn {
    margin: 44px auto 10px;
    width: 203px;
    height: 45px;
    font-size: 16px;
  }
  .popup__nextBtn_white {
    margin: 0 auto 21px;
  }
}
@media screen and (max-width: 600px) {
  .popup {
    width: 284px;
  }
  .popup__title {
    font-size: 24px;
    margin-bottom: 82px;
  }
  .popup__text {
    font-size: 15px;
    line-height: 26px;
  }
  .popup__nextBtn {
    margin: 70px auto 15px;
    width: 253px;
    height: 40px;
    font-size: 16px;
  }
  .popup__nextBtn_white {
    margin: 0 auto 29px;
  }
}
