.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: rgba(231, 242, 253, 0.62);
  z-index: 3;
}
.sidebar::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  opacity: 0.4;
  background: #2f42c2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar__venchy {
  margin-top: 123px;
  height: 95px;
  width: 100px;
}
.sidebar__list {
  margin: 34px 0;
  width: 100%;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}
.sidebar__li {
  width: 100%;
  height: 110px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 17px 17px;
}
.sidebar__li:hover {
  background-color: rgba(233, 243, 254, 0.9);
}
.sidebar__li_phone {
  background-image: url("../../images/phone_prevencher_icon.svg");
}
.sidebar__li_message {
  background-image: url("../../images/message_prevencher_icon.svg");
}
.sidebar__li_video {
  background-image: url("../../images/video_prevencher_icon.svg");
}
.sidebar__li_chat {
  background-image: url("../../images/chat_prevencher_icon.svg");
}
.link_tel {
  display: block;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1300px) {
  .sidebar {
    width: 89px;
  }
  .sidebar__venchy {
    margin-top: 79px;
    height: 72px;
    width: 75px;
  }
  .sidebar__list {
    margin: 30px 0;
  }
  .sidebar__li {
    height: 85px;
    background-size: 15px 15px;
  }
}
@media screen and (max-width: 700px) {
  .sidebar {
    display: none;
  }
}
