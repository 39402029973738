.popup {
  width: 494px;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
}
.popup__title {
  margin: 0 auto 59px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  color: #2f42c2;
}
.popup__img {
  width: 145px;
  height: 145px;
  margin: 61px auto 35px;
}
.popup__text {
  max-width: 344px;
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  color: #000000;
}
.popup__btn {
  margin: 0 auto 27px;
  width: 260px;
  height: 57px;
  font-size: 18px;
}
.popup__btn_type_calendar {
  color: #5d3cc7;
  border: 1px solid #5d3cc7;
  background: transparent;
}

@media screen and (max-width: 1300px) {
  .popup {
    width: 405px;
  }
  .popup__title {
    margin-bottom: 40px;
    font-size: 26px;
  }
  .popup__img {
    width: 112px;
    height: 112px;
    margin: 50px auto 28px;
  }
  .popup__text {
    max-width: 244px;
    font-size: 18px;
  }
  .popup__btn {
    margin: 0 auto 18px;
    width: 213px;
    height: 48px;
    font-size: 16px;
  }
}
@media screen and (max-width: 560px) {
  .popup {
    width: 301px;
    min-height: 329px;
  }
  .popup__title {
    font-size: 19px;
    margin-bottom: 32px;
  }
  .popup__text {
    font-size: 15px;
    margin-bottom: 43px;
  }
  .popup__img {
    width: 88px;
    height: 87px;
    margin: 0 auto;
  }
  .popup__btn {
    width: 159px;
    font-size: 12px;
  }
  .popup__btn_type_calendar {
    margin: 21px auto 15px;
  }
}
