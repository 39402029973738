.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 493px;
  width: 100%;
  min-height: 780px;
  color: #121759;
  box-shadow: 0 0 21px -5px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  z-index: 2;
  position: relative;
  background: #f0f3ff;
}
.login__supportLink {
  display: block;
  color: #000;
  text-align: center;
  font-size: 14px;
  text-decoration-line: underline;
}
.login__arrow {
  position: absolute;
  top: 26px;
  right: 26px;
  cursor: pointer;
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-image: url("../../images/arrow_forward.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.login__title {
  margin: 31px auto 0;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.3;
  color: #5d3cc7;
}
.login__title_sms {
  margin-bottom: 66px;
}
.login__text {
  margin: 12px auto 27px;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #121759;
}
.login__way {
  display: grid;
  width: 390px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "sms email"
    "form form";
}
.login__radioLabel {
  background: transparent;
  border: 0;
  outline: none;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #8a74d8;
  position: relative;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
}
.login__radioLabel_active {
  border-bottom: 2px solid #5d3cc7;
  color: #5d3cc7;
}
.login__radio_sms {
  grid-area: sms;
}
.login__button {
  margin: 0 auto;
  width: 390px;
  height: 60px;
  border-radius: 7px;
  font-size: 28px;
}
.login__button_disabled {
  background: #dbd4f4;
}
.login__button_type_signup {
  color: #006CC9;
  border-color: #006CC9;
  width: 390px;
  max-width: 290px;
}
.login__text_small {
  margin: 0 auto 31px;
  font-weight: 400;
  font-size: 17px;
  line-height: 1;
  text-align: center;
}
.login__form {
  grid-area: form;
  margin: 35px auto 28px;
  max-width: 80%;
  width: 80%;
}
.login__formText {
  margin: 0 auto 31px;
  text-align: right;
  color: #000000;
}
.login__label {
  display: block;
  font-size: 17px;
  line-height: 1;
  color: #000;
  margin: 0 0 12px;
}
.login__input {
  padding: 0 20px;
  width: 390px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  font-family: inherit;
  font-size: 17px;
  min-height: 60px;
  box-sizing: border-box;
}
.login__input_email {
  margin-bottom: 16px;
}
.login__input::placeholder {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ababab;
}
.login__input_invalid {
  background: #ffe9e9;
  border: 1px solid #ff0000;
}
.login__invalid_message {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #ff0000;
  text-align: center;
  margin: 20px auto 0;
}
.login__restorePasswordLink {
  display: block;
  margin: 27px auto 23px;
  width: 100%;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
  color: #121759;
}
.login__bottomBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 100px);
}
.login__alternative {
  margin: 0 auto 33px;
  font-weight: 600;
  font-size: 17px;
  line-height: 1;
  color: #000000;
  position: relative;
  width: 100%;
  text-align: center;
}
.login__alternative::before {
  content: "";
  position: absolute;
  border-bottom: 1px solid #d4d4d4;
  left: 0;
  top: 10px;
  width: 45%;
}
.login__alternative::after {
  content: "";
  position: absolute;
  border-bottom: 0.8px solid #d4d4d4;
  right: 0;
  top: 10px;
  width: 45%;
}
.login__social {
  width: 100%;
  font-size: 14px;
}
.login__socialList {
  margin: 0 auto 50px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  gap: 23px;
}
.login__socialList_column {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 85px;
  flex-direction: column;
  gap: 11px;
}
.login__socialBtn {
  font-family: inherit;
  cursor: pointer;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  gap: 13px;
}
.login__socialBtn_google {
  background-image: url("../../images/google_icon.svg");
}
.login__socialBtn_apple {
  background-image: url("../../images/apple_icon.svg");
}
.login__socialBtn_facebook {
  background-image: url("../../images/facebook_icon.svg");
}
.login__socialBtn_long {
  font-size: 18px;
  height: 57px;
  width: 260px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}
.login__socialImg {
  margin-left: 13px;
}
.login__urgent {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.13;
  margin: 0 auto 20px;
  display: inline-block;
}
.login__conditions {
  display: inline-block;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: #000000;
}
.login__text_margin {
  margin-top: 62px;
  margin-bottom: 27px;
}
.login__enterCode {
  list-style-type: none;
  padding: 0;
  margin: 0;
  direction: ltr;
  display: flex;
  gap: 10px;
}
.login__number {
  width: 55px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #dbd6ef;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  font-size: 17px;
}
.login__number:focus {
  background: #dbd6ef;
  border: 1px solid #5d3cc7;
  outline: none;
}

.login__number_empty {
  background: #dbd6ef;
  border: 1px solid #5d3cc7;
}
.login__number::placeholder {
  width: 100%;
  text-align: center;
}
.login__enterCodeLabel {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #111111;
  margin-bottom: 15px;
}
.login__sendAgain {
  color: #000;
  display: block;
  margin: 27px auto 41px;
  width: 100%;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}
.login__sendAgain_underlined {
  text-decoration: underline;
  margin-right: 4px;
  position: relative;
}
.login__link {
  margin: 0 3px 0 0;
  font-size: 14px;
  text-decoration: none;
  color: #000000;
  border-bottom: 1px solid #000000;
  transition: all 0.3s;
}
.login__link:hover {
  opacity: 0.8;
}
/* hide arrows from number input - Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* hide arrows from number input - Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 1400px) {
  .login {
    margin-top: 0;
    max-width: 330px;
    min-height: unset;
    border-radius: 15px;
    padding-bottom: 5px;
  }
  .login__arrow {
    width: 17px;
    height: 17px;
    top: 20px;
    right: 18px;
  }
  .loginTherapistPageLogo{
    width: 117px;
    height: 33px;
  }
  .login__title {
    margin: 20px auto 0;
    font-size: 24px;
  }
  .login__title_sms {
    margin-bottom: 40px;
  }
  .login__enterCodeLabel {
    font-size: 13px;
    margin-bottom: 8px;
  }
  .login__text {
    font-size: 16px;
    line-height: 1;
    margin: 12px auto 22px;
  }
  .login__way {
    width: calc(100% - 75px);
  }
  .login__radioLabel {
    height: 30px;
    font-size: 16px;
    line-height: 1;
  }
  .login__form {
    margin: 26px auto 0;
  }
  .login__formText {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .login__label {
    font-size: 14px;
    line-height: 16px;
  }
  .login__input::placeholder {
    font-size: 12px;
  }
  .login__input {
    width: 100%;
    min-width: unset;
    min-height: 42px;
    border-radius: 6px;
    font-size: 15px;
  }
  .login__restorePasswordLink {
    font-size: 12px;
    line-height: 12px;
    margin: 18px auto 20px;
  }
  .login__button {
    width: 100%;
    height: 42px;
    font-size: 20px;
  }
  .login__button_type_signup {
    width: 264px;
    max-width: 80%;
    margin-top: 10px;
  }
  .login__socialBtn {
    background-size: 23px 23px;
    width: 26px;
    height: 26px;
  }
  .login__socialBtn_long {
    padding: 12px;
    width: 205px;
    height: 45px;
    font-size: 14px;
  }
  .login__alternative {
    margin: 22px 0 16px;
    font-size: 14px;
    line-height: 1;
  }
  .login__socialList {
    margin: 0 auto;
  }
  .login__socialList_column {
    margin: 28px auto 28px;
    gap: 8px;
  }
  .login__text_small {
    margin-bottom: 23px;
  }
  .login__input_email {
    margin-bottom: 12px;
  }
  .login__text_margin {
    margin-top: 12px;
    margin-bottom: 5px;
  }
  .login__number {
    width: 43px;
    height: 43px;
    font-size: 15px;
  }
  .login__sendAgain {
    margin: 24px auto 1px;
    font-size: 13px;
    line-height: 1;
  }
  .login__bottomBar {
    width: calc(100% - 80px);
  }
  .login__socialBtn {
    gap: 10px;
  }
  .login__conditions {
    font-size: 12px;
    line-height: 1;
    margin: 36px auto 0;
  }
  .login__link {
    font-size: 12px;
  }
  .login__supportLink {
    font-size: 12px;
    margin: 15px 0;
  }
}
@media screen and (max-width: 1300px) {
  .login {
    max-width: 320px;
  }
  .login__radioLabel {
    height: 26px;
  }
  .login__form {
    margin: 22px auto 0;
  }
  .login__formText {
    font-size: 15px;
    margin-bottom: 14px;
  }
  .login__label {
    font-size: 14px;
    line-height: 1.1;
  }
  .login__input {
    min-height: 38px;
  }
  .login__restorePasswordLink {
    margin: 14px auto 18px;
  }
  .login__button {
    width: 100%;
    height: 42px;
    font-size: 20px;
  }
  .login__button_type_signup {
    width: 264px;
    max-width: 80%;
    margin-top: 10px;
  }
  .login__socialBtn_long {
    padding: 12px;
    width: 205px;
    height: 45px;
    font-size: 14px;
  }
  .login__alternative {
    margin: 18px 0 4px;
  }
  .login__socialList_column {
    margin: 28px auto 28px;
    gap: 8px;
  }
  .login__text_small {
    margin-bottom: 23px;
  }
  .login__input_email {
    margin-bottom: 12px;
  }
  .login__text_margin {
    margin-top: 12px;
    margin-bottom: 5px;
  }
  .login__number {
    width: 43px;
    height: 43px;
    font-size: 15px;
  }
  .login__sendAgain {
    margin: 24px auto 1px;
    font-size: 13px;
    line-height: 1;
  }
  .login__bottomBar {
    width: calc(100% - 80px);
  }
  .login__conditions {
    margin: 20px auto 0;
  }
}
@media screen and (max-width: 1000px){
  .login__conditions{
    margin-top: 20px;
  }
  .login__supportLink{
    margin-top: 12px;
  }
}

@media screen and (max-width: 780px) {
  .login {
    width: 284px;
  }
  .login__title {
    font-size: 24px;
    line-height: 31px;
    margin-top: 21px;
  }
  .login__title_sms {
    margin-bottom: 35px;
  }
  .login__text {
    font-size: 13px;
    line-height: 1;
    margin: 7px auto 20px;
  }
  .login__text_small {
    margin-bottom: 13px;
  }
  .login__way {
    width: calc(100% - 35px);
  }
  .login__radioLabel {
    font-size: 13px;
  }
  .login__form {
    margin-top: 23px;
  }
  .login__formText {
    font-size: 13px;
    margin-bottom: 15px;
  }
  .login__label {
    margin: 0;
  }
  .login__input {
    min-width: unset;
    min-height: 36px;
    width: 225px;
    padding: 10px;
  }
  .login__invalid_message {
    font-size: 12px;
    margin-top: 12px;
  }
  .login__button {
    width: 100%;
    height: 38px;
    font-size: 18px;
  }
  .login__alternative {
    font-size: 12px;
    line-height: 13px;
    margin: 17px auto 7px;
    max-width: 70%;
  }
  .login__conditions {
    margin-top: 19px;
  }
  .login__enterCodeLabel {
    font-size: 10px;
    line-height: 16px;
  }
  .login__enterCode {
    gap: 6px;
  }
  .login__sendAgain {
    font-size: 10px;
    margin-bottom: 12px;
  }
  .login__socialList_column {
    margin: 10px 0;
    gap: 6px;
  }
  .login__socialBtn_long {
    width: 150px;
    height: 36px;
    padding: 9px;
    font-size: 12px;
  }
  .login__socialBtn_small {
    width: 17px;
    height: 17px;
    background-size: contain;
  }
}
@media screen and (max-width: 600px) {
  .login__arrow {
    display: none;
  }
}
