.faq {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}
.faq__title {
  font-weight: 600;
  font-size: 24px;
  color: #2f42c2;
  margin: 0 auto 29px;
}
.faq__list {
  display: flex;
  flex-direction: column;
  width: calc(100% - 210px);
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 21px;
}

@media screen and (max-width: 1400px) {
  .faq__title {
    margin-bottom: 42px;
  }
  .faq__list {
    gap: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .faq__list {
    width: calc(100% - 74px);
  }
}
@media screen and (max-width: 740px) {
  .faq__title {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
