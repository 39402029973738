.myTreatment__li {
  cursor: pointer;
  box-sizing: border-box;
  width: 438px;
  height: 88px;
  background: #ffffff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin: 0;
  padding: 19px 17px;
  display: flex;
  gap: 15px;
  position: relative;
}
.myTreatment__li:after {
  content: "";
  width: 8px;
  height: 16px;
  position: absolute;
  top: 43%;
  left: 42px;
  background-image: url("../../images/arrow_left_gray.svg");
}
.myTreatment__online {
  position: relative;
}
.myTreatment__avatar {
  border-radius: 100%;
  margin: 0;
  width: 57px;
  height: 57px;
  object-fit: cover;
}
.myTreatment__online:before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #a9a9a9;
  border-radius: 100%;
  z-index: 2;
  position: absolute;
}
.myTreatment__online_active:before {
  background-color: #27e917;
}

.myTreatment__therapist {
  display: flex;
  flex-direction: column;
}
.myTreatment__name {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #121759;
}
.myTreatment__therapistInfo {
  display: flex;
  gap: 10px;
}
.myTreatment__status {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #626262;
}
.myTreatment__tag {
  background-color: #f6f5f5;
  border-radius: 10100px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  color: #323232;
  margin: 5px 0 0;
  padding: 2px 10px;
}

@media screen and (max-width: 1300px) {
  .myTreatment__li {
    width: 100%;
    height: 64px;
    padding: 14px 12px;
  }
  .myTreatment__li:after {
    left: 20px;
    top: 37%;
  }
  .myTreatment__avatar {
    width: 40px;
    height: 40px;
  }
  .myTreatment__name {
    font-size: 16px;
    line-height: 21px;
  }
  .myTreatment__status {
    font-size: 13px;
  }
  .myTreatment__tag {
    margin-top: 3px;
  }
}
@media screen and (max-width: 600px) {
  .myTreatment__li {
    width: 317px;
  }
}
