.myNotifications {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 40px;
}
.myNotifications__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.31;
  color: #2f42c2;
  margin: 0 auto 100px;
}
.myNotifications__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.myNotifications__variant {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d3d3d3;
  width: 330px;
  padding: 23px 0 7px;
}
.myNotifications__variant:last-of-type {
  border-bottom: none;
}
.myNotifications__label {
}
.myNotifications__name {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  text-align: right;
  color: #111;
}
.myNotifications__note {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.8;
  text-align: right;
  color: #afa7a7;
}
.myNotifications__btn_change {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.8;
  display: flex;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;
  color: #afa7a7;
  border: none;
  background-color: transparent;
}
.myNotifications__toggle {
  width: 56px;
  height: 0;
  cursor: pointer;
}
.myNotifications__toggle_active {
}
.myNotifications__toggle_disabled {
}
.myNotifications__toggle:before {
  box-sizing: border-box;
  display: block;
  content: "";
  width: 56px;
  height: 28px;
  border-radius: 100px;
  transition: all 0.3s;
}
.myNotifications__toggle_disabled:before {
  border: 1px solid #d3d3d3;
  background-image: url("../../images/circle_grey.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 32px;
}
.myNotifications__toggle_active:before {
  border-color: #e1e1e1;
  background: #dbd6ef url("../../images/circle_purple.svg") no-repeat;
  background-position-y: center;
  background-position-x: 4px;
}
@media screen and (max-width: 1300px) {
  .myNotifications__title {
    margin-bottom: 36px;
  }
  .myNotifications__toggle:before {
    width: 50px;
    height: 24px;
  }
  .myNotifications__toggle_disabled:before {
    background-size: 18px 18px;
    background-position-x: 28px;
  }
  .myNotifications__toggle_active:before {
    background-size: 18px 18px;
    background-position-x: 4px;
  }
}
@media screen and (max-width: 1040px) {
  .myNotifications__title {
    margin-bottom: 66px;
  }
  .myNotifications__list {
    box-sizing: border-box;
    padding: 2px 48px;
    border-radius: 32px;
    background: rgba(255, 255, 255, 0.65);
    width: 584px;
  }
  .myNotifications__variant {
    width: 486px;
    padding: 28px 80px 19px 0;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 112px 36px;
  }
  .myNotifications__name {
    font-size: 16px;
  }
  .myNotifications__toggle:before {
    width: 53px;
    height: 27px;
  }
  .myNotifications__toggle_disabled:before {
    background-size: 20px 20px;
    background-position-x: 28px;
  }
  .myNotifications__toggle_active:before {
    background-size: 20px 20px;
    background-position-x: 4px;
  }
  .myNotifications__btn_change {
    align-self: flex-start;
    line-height: 2;
  }
}
@media screen and (max-width: 740px) {
  .myNotifications__list {
    border-radius: 28px;
  }
  .myNotifications__title {
    font-size: 18px;
    margin-bottom: 40px;
  }
  .myNotifications__list {
    width: 318px;
    padding: 10px;
  }
  .myNotifications__variant {
    display: flex;
    width: 100%;
    padding: 20px 0 12px;
  }
  .myNotifications__name {
    font-size: 17px;
  }
  .myNotifications__note {
    color: #606060;
  }
  .myNotifications__btn_change {
    font-size: 15px;
    line-height: 1.1;
    color: #606060;
  }
}
