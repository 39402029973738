.section {
  width: 96%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 118px;
}
.guests{
  display: flex;
  flex-direction: column;
  max-width: 700px;
  align-items: center;
  min-height: 100vh;
}
.guests_title{
  font-weight: 600;
  font-size: 28px;
  line-height: 1.3;
  color: #2f42c2;
}
.guests__link{
  align-self: flex-start;
  color: #8d8d8d;
  cursor: pointer;
  font-size: 16px;
  margin-left: 12px;
  position: absolute;
  top: 20px;
  left: 20px;
  text-decoration: none;
}
.content {
  width: 100%;
  display: grid;
  grid-template-rows: 67px 1fr;
  align-items: center;
  margin-bottom: 20px;
}
.venchy {
  margin: 22px auto 0;
  height: 181px;
  width: 174px;
}
.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 41px 0 0;
  gap: 40px;
}
.tabs_horizontal {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  list-style-type: none;
  padding: 0;
  margin: 14px 0 0;
  width: 95%;
  border-bottom: 1px solid #d1d1d1;
}
.tabBtn {
  cursor: pointer;
  outline: none;
  border: none;
  width: 397px;
  height: 79px;
  background: #2f42c2;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
}
.tabBtn_horizontal {
  width: 100%;
  height: 65px;
  background-color: transparent;
  font-size: 18px;
  line-height: 24px;
  color: #2f42c2;
  box-shadow: none;
  border-radius: 0;
}
.tabBtn_active {
  background-color: #e7f2fd;
}
.btnImg {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(231, 242, 253, 0.62);
}
.sidebar__venchy {
  margin-top: 123px;
  height: 95px;
  width: 100px;
}
.sidebar__list {
  margin: 50px 0;
  width: 100%;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}
.sidebar__li {
  width: 100%;
  height: 118px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 17px 17px;
}
.sidebar__li_phone {
  background-image: url("../../images/phone_prevencher_icon.svg");
}
.sidebar__li_message {
  background-image: url("../../images/message_prevencher_icon.svg");
}
.sidebar__li_video {
  background-image: url("../../images/video_prevencher_icon.svg");
}
.sidebar__li_chat {
  background-image: url("../../images/chat_prevencher_icon.svg");
}

@media screen and (max-width: 1300px) {
  .section {
    grid-template-columns: 1fr 89px;
  }
  .guests_title{
    font-size: 24px;
    }

  .venchy {
    height: 115px;
    width: 115px;
  }
  .tabs {
    margin: 43px 0 0;
    gap: 26px;
  }
  .tabBtn {
    width: 258px;
    height: 52px;
    font-weight: 600;
    font-size: 20px;
    line-height: 1;
  }
  .tabBtn_horizontal {
    width: 100%;
    height: 52px;
    font-size: 14px;
    line-height: 19px;
  }
  .btnImg {
    width: 30px;
    height: 30px;
  }
  .sidebar__venchy {
    margin-top: 79px;
    height: 72px;
    width: 75px;
  }
  .sidebar__li {
    height: 89px;
    background-size: 15px 15px;
  }
}
@media screen and (max-width: 700px) {
  .tabs_horizontal {
    display: none;
  }
  .section {
    grid-template-columns: 1fr;
  }
  .content{
    grid-template-rows: 1fr;
    padding: 0 10px 0 0;
  }
  .sidebar {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .venchy {
    width: 75px;
    height: 75px;
  }
  .tabs {
    margin: 32px 0 0;
    gap: 24px;
  }
  .tabBtn {
    width: 239px;
    height: 48px;
    font-size: 20px;
  }
}
