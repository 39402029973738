.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 913px;
  width: 88%;
  box-sizing: border-box;
  padding: 25px 0;
}
.section__title {
  margin: 0 auto 31px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #2f42c2;
}
.section__title_list {
  margin: 31px auto;
}
.searchOptions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  list-style-type: none;
  margin: 0 0 2px;
  padding: 0 22px;
  box-sizing: border-box;
}
.searchOption {
  cursor: pointer;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-decoration: underline;
  color: #000000;
}
.searchOption_active {
  color: #2f42c2;
  text-decoration: none;
}
.input {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #5d3cc7;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 14px 21px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}
.text {
  margin: 27px auto 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #111111;
}
.contactOptions {
  width: 100%;
  max-width: 628px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.contactOption {
  width: 269px;
  padding: 14px 42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.contactOption__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #2f42c2;
  margin: 0;
}
.contactOption__img {
  margin: 22px 0 20px;
  width: 63px;
  height: 63px;
}
.contactOption__btn {
  background: #5d3cc7;
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 27px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 11px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}
.section__footer {
  margin: 19px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.67);
  padding: 31px 21px;
}
.footerTitle {
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 600;
}
.footerText {
  margin: 0;
}
.questionList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.questionList_prevencher {
  margin-top: 20px;
}
.variants {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.variant {
  cursor: pointer;
  padding: 7px 16px;
}
.variant:hover {
  background-color: #dbd6ef;
}
@media screen and (max-width: 1200px) {
  .section {
    padding: 16px 0;
    max-width: 724px;
  }
  .section__title {
    margin: 0 auto 28px;
  }
  .section__title_list {
    margin: 28px auto;
  }
  .searchOption {
    font-size: 15px;
    line-height: 20px;
  }
  .input {
    padding: 11px 15px;
    font-size: 16px;
    line-height: 21px;
  }
  .text {
    margin: 20px auto 8px;
    font-size: 16px;
    line-height: 21px;
  }
  .contactOptions {
    max-width: 473px;
  }
  .contactOption {
    width: 216px;
    padding: 8px 27px;
  }
  .contactOption__title {
    font-size: 18px;
    line-height: 24px;
  }
  .contactOption__img {
    margin: 29px 0 12px;
  }
  .section__footer {
    padding: 22px 25px 10px;
    font-size: 15px;
    line-height: 23px;
  }
  .footerTitle {
    margin-bottom: 3px;
    font-size: 16px;
    line-height: 18px;
  }
  .footerText {
    margin: 0;
  }
  .variants {
    font-size: 16px;
    line-height: 21px;
  }
}
@media screen and (max-width: 700px) {
  .section {
    padding: 10px 0;
    width: calc(100% - 70px);
  }
  .section__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 59px;
  }
  .section__title_list {
    margin-bottom: 0;
  }
  .searchOptions {
    padding: 0;
    margin: 0 0 5px;
  }
  .text {
    margin: 13px auto 16px;
    font-size: 15px;
    line-height: 20px;
  }
  .contactOptions {
    justify-content: center;
    gap: 45px;
  }
  .contactOption {
    padding: 0;
    box-shadow: none;
    width: 40%;
    font-size: 15px;
    line-height: 32px;
  }
  .contactOption__title {
    font-size: 15px;
  }
  .contactOption__img {
    margin: 0 0 15px;
    width: 40px;
    height: 40px;
  }
  .contactOption__btn {
    font-size: 15px;
    width: 129px;
  }
  .section__footer {
    margin-top: 17px;
    border-radius: 20px;
    padding: 11px;
  }
  .questionList {
    margin-top: 17px;
    gap: 10px;
  }
  .input {
    font-size: 16px;
    padding: 10px;
  }
}
