.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: #000000;
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.main {
  box-sizing: border-box;
  width: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  direction: rtl;
  height: 100%;
  margin: auto;
}
.logo{
  width: 151px;
  margin: 57px auto 28px;
}
@media screen and (max-width: 1400px) {
  .content {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .logo{
    width: 111px;
    margin: 24px auto 14px;
  }
}

@media screen and (max-width: 600px) {
  .content_type_therapists{
    background-size: contain;
  }
}