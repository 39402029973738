.notifications {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  width: 328px;
  background: rgba(231, 242, 253, 0.8);
  border-radius: 0 55px 0 0;
  z-index: 5;
  translate: -328px;
  transition: all 0.3s;
  box-sizing: border-box;
  padding: 50px 24px;
}
.notifications_visible {
  translate: 0;
}


.header {
  display: flex;
  width: 90%;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  text-align: right;
  color: #2f42c2;
}
.title {
  margin: 0;
}
.bell {
  width: 28px;
  height: 32px;
  background: url("../../images/bell_icon.svg") no-repeat;
  background-size: contain;
}
.bell_active {
  position: relative;
}
.bell_active:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  right: 2px;
  background: rgba(57, 23, 169, 0.81);
  border-radius: 100%;
}
.text {
  margin: 36px auto;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #2f42c2;
}
.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;
}
.list::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}
/* Handle */
.list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    opacity: 0.4;
    background: #2f42c2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.notification {
  cursor: pointer;
  background: #ffffff;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
  text-align: right;
  color: #626262;
  min-height: 64px;
  box-sizing: border-box;
  padding: 8px 23px;
}
.notification_unread {
  background: #6386f9;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
  color: #fff;
}
@media screen and (max-width: 1200px) {
  .notifications {
    width: 281px;
    translate: -282px;
    padding: 50px 19px;
  }
  .notifications_visible {
    translate: 0;
  }
  .header {
    gap: 32px;
    font-size: 20px;
  }
  .text {
    margin-bottom: 30px;
    font-size: 18px;
  }
  .list {
    gap: 11px;
  }
}
@media screen and (max-width: 600px) {
  .notifications {
    width: 221px;
    translate: -222px;
    padding: 34px 13px;
    background: rgba(231, 242, 253, 0.9);
  }
  .notifications_visible {
    translate: 0;
  }
  .header {
    gap: 38px;
    font-size: 18px;
  }
  .bell {
    width: 20px;
    height: 24px;
  }
  .bell_active:after {
    width: 6px;
    height: 6px;
    top: 4px;
    right: 2px;
  }
  .text {
    margin: 28px auto 42px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #2f42c2;
  }
  .list {
    gap: 11px;
  }
  .notification {
    font-size: 15px;
    line-height: 1.3;
    padding: 8px 10px;
  }
}
