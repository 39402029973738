.section {
  display: flex;
  flex-direction: column;
  max-width: 872px;
  align-items: center;
  color: #111111;
  padding-bottom: 60px;
}
.section__title {
  margin: 25px auto 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #2F42C2;
}
.section__text {
  margin: 0 auto 21px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}
.practice {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 46px;
  padding: 42px 37px 20px;
  background: rgba(255, 255, 255, 0.65);
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  margin-bottom: 54px;
  position: relative;
}
.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.video {
  width: 100%;
  object-fit: fill;
}
.videoTitle {
  margin: 0 auto 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  text-align: right;
  color: #000;
}
.videoAbout {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.39;
  color: #626262;
}
.text_purple {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  color: #2f42c2;
}
.planBtn {
  margin: 32px auto 0;
  width: 56%;
  height: 52px;
}
.practice__sectionTitle {
  position: absolute;
  margin: 0;
  top: -20px;
  left: calc((100% - 162px) / 2);
  width: fit-content;
  height: 41px;
  background: #006cc9;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 25px;
  color: #ffffff;
  padding: 10px 18px;
  box-sizing: border-box;
}
.practice__swiper {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 341px);
  overflow: hidden;
  list-style-type: none;
  justify-content: space-between;
}
.swiper__text{
  margin: 0 0 5px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: right;
  color: #111111;

}

@media screen and (max-width: 1400px) {
  .section {
    max-width: calc(100% - 152px);
    padding-bottom: 30px;
  }
  .section__title {
    margin: 11px auto 16px;
  }
  .practice {
    gap: 41px;
    padding: 38px 33px 18px;
    border-radius: 21px;
    margin-bottom: 66px;
  }
  .content {
    gap: 17px;
  }
  .videoTitle {
    margin: 0 auto 4px;
    font-size: 18px;
  }
  .videoAbout {
    font-size: 16px;
  }
  .text_purple {
    font-size: 18px;
  }
  .planBtn {
    margin: 28px auto 0;
    height: 47px;
    font-size: 16px;
  }
  .practice__swiper {
    grid-template-columns: repeat(2, 292px);
  }
  .practice__sectionTitle {
    top: -22px;
    height: 45px;
    font-size: 21px;
  }
}
@media screen and (max-width: 980px) {
  .practice__swiper {
    grid-template-columns: repeat(2, 46%);
  }

}
@media screen and (max-width: 760px) {
  .section{
    max-width: calc(100% - 42px);
    padding-bottom: 20px;
  }
  .section__title {
    font-size: 18px;
    margin: 10px auto 16px;
  }
  .section__text{
    display: none;
  }
  .practice__sectionTitle {
    top: -23px;
    height: 47px;
    padding: 12px 20px;
    left: calc((100% - 88px) / 2);
    font-size: 16px;
  }
  .practice__swiper {
    grid-template-columns: 1fr;
    justify-items: center;
    background: rgba(255, 255, 255, 0.65);
    border-radius: 19px;
    box-sizing: border-box;
    padding: 56px 37px 20px;
    gap: 15px;
  }
  .swiper__text{
    margin: 0 0 8px;
    font-size: 20px;
    text-align: center;
  }
  .section__text {
    margin: 50px auto 6px;
    font-size: 18px;
    line-height: 24px;
  }
  .practice {
    gap: 33px;
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
  .content {
    box-sizing: border-box;
    padding: 14px 25px;
    grid-template-columns: 1fr;
    gap: 11px;
    background: rgba(255, 255, 255, 0.65);
    border-radius: 19px;
  }
  .video {
    width: 100%;
    cursor: pointer;
  }
  .videoTitle {
    margin: 0 auto 10px;
    font-size: 18px;
    line-height: 1.2;
    text-align: right;
    color: #111111;
  }
  .videoAbout {
    font-size: 16px;
  }
  .text_purple {
    margin: 0 auto;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    color: #111111;
    max-width: 55%;
  }
  .practice__sectionTitle {
    left: calc((100% - 141px) / 2);
  }
  .planBtn {
    margin: 14px auto 17px;
    width: 83%;
    height: 52px;
  }
}
