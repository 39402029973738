.section {
  width: calc(100% - 180px);
  max-width: 988px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section__title {
  margin: 25px auto 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: #2f42c2;
}
.nextBtn {
  width: 260px;
  font-size: 18px;
}
.noPrescriptions {
  margin: 20px auto 55px;
  font-size: 18px;
}
.prescriptionList {
  width: 100%;
  list-style-type: none;
  margin: 26px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.prescriptionList:nth-child(3) {
  margin-top: 0;
}
.prescriptionLi {
  cursor: pointer;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  position: relative;
  height: 116px;
  margin-bottom: 63px;
}
.prescriptionLi_long {
  height: unset;
}
.prescriptionLi:after {
  position: absolute;
  content: "";
  width: 30px;
  height: 15px;
  background-image: url("../../images/arrow_down.svg");
  background-repeat: no-repeat;
  background-size: contain;
  left: 31px;
  top: 50px;
  cursor: pointer;
}
.prescriptionLi_long:after {
  transform: rotate(180deg);
}
.prescriptionLi .prescription__content,
.prescriptionLi .btn,
.prescriptionLi .scanOption,
.prescriptionLi .text,
.prescriptionLi .scannedList {
  display: none;
}
.prescriptionLi_long .prescription__content,
.prescriptionLi_long .btn,
.prescriptionLi_long .scanOption,
.prescriptionLi_long .text,
.prescriptionLi_long .scannedList {
  display: flex;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 231px;
  height: 49px;
  margin: -32px auto 0;
  background: #006cc9;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  position: relative;
}
.title_color_blue {
  background-color: #33a3ff;
}
.prescription {
  display: flex;
  justify-content: center;
}
.prescription__heading {
  position: absolute;
  top: 12px;
  right: 30px;
}
.prescription__date {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 2.3;
  color: #626262;
}
.prescription__title {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.25;
  text-align: right;
  color: #00214e;
}
.prescription__expired {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.8;
  color: #626262;
}
.prescription__content {
  display: flex;
  flex-direction: column;
}
.therapist {
  box-sizing: border-box;
  padding: 37px 0 0;
  display: flex;
  justify-content: center;
  gap: 11px;
  position: relative;
}
.therapist__online {
  position: relative;
}
.therapist__online_active {
}
.therapist__online:before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #a9a9a9;
  border-radius: 100%;
  z-index: 2;
  position: absolute;
}
.therapist__online_active:before {
  background-color: #27e917;
}
.therapist__avatar {
  width: 57px;
  height: 57px;
}
.therapist__name {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: #2c2b2b;
}
.therapist__statusInfo {
  display: flex;
  align-items: center;
}
.therapist__status {
  margin: 0 0 0 7px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
  color: #626262;
}
.therapist__tag {
  margin: 0;
  box-sizing: border-box;
  padding: 3px 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  color: #323232;
  background: #f6f5f5;
  border-radius: 10100px;
}
.prescription__expirationInfo {
  direction: ltr;
  margin: 0;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.78;
  color: #626262;
}
.prescription__expiredTag {
  margin: 19px auto 22px;
  height: 38px;
  width: 165px;
  border-radius: 10100px;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #626262;
}
.prescription__expiredTag_expired {
  background: #cce2f4;
  color: #fff;
}
.prescription__expiredText {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 2.3;
  color: #626262;
  text-align: center;
}
.prescription__expiredTag:before {
  position: absolute;
  top: 15px;
  right: 38px;
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  background: #d9d9d9;
  border-radius: 100%;
}
.prescription__expiredTag_expired:before {
  right: 26px;
  background: #fff;
}
.instructionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.instructions {
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.7;
  color: #000000;
  text-align: center;
}
.instructions__text {
  margin: 0 auto 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1;
  text-align: center;
  max-width: 340px;
  color: #626262;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 26px;
  outline: none;
  cursor: pointer;
  height: 57px;
  width: 260px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid rgba(57, 23, 169, 0.8);
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.11;
  text-align: center;
  color: rgba(57, 23, 169, 0.81);
}
.btn_type_print {
}
.btn_type_renew {
  margin-top: 103px;
}
.btn_type_print:before {
  content: url("../../images/print_icon.svg");
  display: block;
  margin-left: 11px;
  height: 18px;
}
.scanTitle {
  display: flex;
  margin: 28px 24px 0 0;
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: right;
  color: #00214e;
}
.scanIcon {
  margin-right: 30px;
  display: inline-block;
  width: 32px;
  height: 32px;
  background-image: url("../../images/scan_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.scanOption {
  width: fit-content;
  margin: 25px auto;
}
.input {
  display: none;
}
.scannedList {
  display: flex;
  flex-direction: column;
  width: 84%;
  margin: 25px auto;
  padding: 0;
  list-style-type: none;
  justify-content: center;
  gap: 24px;
}
.scannedLi {
  width: 100%;
  display: grid;
  grid-template-columns: 20px 30% 30% 25%;
  align-items: center;
  gap: 32px;
}
.scannedLi:before {
  content: url("../../images/document_icon.svg");
  width: 16px;
  height: 21px;
}
.label {
  display: flex;
  align-items: center;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  white-space: nowrap;
  color: #626262;
}
.info {
  display: inline-block;
  color: #000;
  margin-right: 3px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text {
  display: flex;
  margin: 25px auto;
  justify-content: center;
}

@media screen and (max-width: 1300px) {
  .section {
    width: calc(100% - 140px);
  }
  .section__title {
    margin: 11px auto 0;
  }
  .img {
    margin-top: 34px;
  }
  .noPrescriptions {
    margin-bottom: 41px;
  }
  .nextBtn {
    width: 260px;
  }
  .prescriptionList {
    margin-top: 51px;
  }
  .prescriptionLi {
    height: 86px;
    margin-bottom: 52px;
  }
  .prescriptionLi:after {
    width: 22px;
    height: 11px;
    left: 26px;
    top: 37px;
  }
  .prescriptionLi_long {
    height: unset;
  }
  .title {
    width: 158px;
    height: 36px;
    font-size: 18px;
    margin-top: -18px;
  }
  .prescription__heading {
    top: 2px;
    right: 25px;
  }
  .prescription__title {
    font-size: 19px;
    line-height: 1;
  }
  .prescription__expired {
    font-size: 16px;
  }
  .prescription__expiredText {
    line-height: 1.8;
  }
  .therapist {
    padding: 23px 0 0;
    gap: 13px;
  }
  .therapist__online:before {
    width: 12px;
    height: 12px;
  }
  .therapist__avatar {
    width: 40px;
    height: 40px;
  }
  .therapist__name {
    font-size: 18px;
  }
  .prescription__expirationInfo {
    font-size: 14px;
  }
  .prescription__expiredTag {
    margin: 10px auto 15px;
    height: 31px;
    font-size: 16px;
  }
  .prescription__expiredTag:before {
    top: 11px;
  }
  .btn, .nextBtn {
    margin-bottom: 11px;
    height: 47px;
  }
  .btn_type_renew {
    margin-top: 60px;
  }
  .scanTitle {
    margin-top: 10px;
    font-size: 20px;
  }
  .scanIcon {
    margin-right: 10px;
    width: 22px;
    background-size: contain;
  }
  .scannedLi {
    grid-template-columns: 20px 30% 30% 25%;
    align-items: center;
    gap: 32px;
  }
  .label {
    font-size: 16px;
  }
  .text {
    font-size: 18px;
  }
}
@media screen and (max-width: 900px) {
  .section {
    width: calc(100% - 74px);
    padding-bottom: 20px;
  }
  .scannedLi {
    grid-template-columns: 30% 30% 25%;
    gap: 22px;
  }
  .scannedLi:before {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .section__title {
    margin: 10px auto 0;
    font-size: 18px;
  }
  .img {
    width: 170px;
    height: 120px;
    margin-top: 32px;
  }
  .noPrescriptions {
    font-size: 16px;
  }
  .prescriptionList {
    margin-top: 36px;
  }
  .prescriptionLi {
    height: 65px;
    margin-bottom: 35px;
  }
  .prescriptionLi_long {
    height: unset;
  }
  .prescriptionLi:after {
    width: 12px;
    height: 6px;
    left: 17px;
    top: 30px;
  }
  .btn {
    font-size: 16px;
    width: 190px;
    height: 44px;
  }
  .title {
    width: 144px;
    height: 32px;
    font-size: 16px;
    margin-top: -17px;
  }
  .prescription {
    flex-direction: column;
    align-items: center;
  }
  .prescription__heading {
    right: 18px;
  }
  .prescriptionLi_long .prescription__heading {
    margin-top: 13px;
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .prescription__date {
    line-height: 1.5;
  }
  .prescription__title {
    font-size: 16px;
  }
  .prescription__expired {
    font-size: 14px;
  }
  .therapist,
  .prescription__expiredText,
  .prescription__expiredTag,
  .scanIcon {
    display: none;
  }
  .instructions {
    line-height: 1.8;
  }
  .instructions__text {
    font-size: 16px;
    line-height: 1.2;
  }
  .scanTitle {
    font-size: 16px;
    margin: 5px 22px;
  }
  .scannedLi {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .text {
    margin: 0;
    font-size: 14px;
  }
  .info {
    max-width: 198px;
  }
}

@media screen and (max-width: 600px) {
  .btn, .nextBtn {
    width: 249px;
    height: 47px;
    margin: 0 auto 10px;
    border: none;
    box-shadow: none;
  }
  .btn_type_renew {
    margin-top: 25px;
    border: 1px solid rgba(57, 23, 169, 0.8);
  }
  .noPrescriptions{
    margin-bottom: 66px;
  }
}
