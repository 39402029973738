.mySubscription {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}
.mySubscription__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: #2f42c2;
  margin: 0 auto 20px;
}
.mySubscription__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mySubscription__list {
  display: flex;
  flex-wrap: wrap;
  width: 98%;
  justify-content: center;
  align-items: center;
  gap: 46px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.mySubscription__variant {
  width: 313px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 24px 24px 14px;
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 28px rgba(0, 0, 0, 0.06);
  border-radius: 9px;
}
.mySubscription__variant_active {
  border: 1px solid #5d3cc7;
}
.mySubscription__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.mySubscription__name {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.7;
  text-align: right;
  color: #000000;
}
.mySubscription__cost {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  color: #000000;
}
.mySubscription__description {
  margin: 10px 0 17px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: right;
  color: #afa7a7;
}
.mySubscription__btn {
  margin: 0 auto;
  height: 48px;
  width: 96%;
  background: #e0daf4;
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06);
  border-radius: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5d3cc7;
  cursor: pointer;
}
.mySubscription__btn_done {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  border: 1px solid #d5d5d5;
  background: #0044c9 url("../../images/check_white.svg") no-repeat center;
}
.mySubscription__btn {
  width: 221px;
  height: 40px;
  background: #5d3cc7;
  color: #fff;
  font-size: 14px;
}

.mySubscription__errorMessage {
  color: #ff0000;
}
@media screen and (max-width: 1400px) {
  .mySubscription__list {
    gap: 30px;
  }
  .mySubscription__name {
    font-size: 15px;
  }
  .mySubscription__description {
    font-size: 15px;
    line-height: 1;
  }
  .mySubscription__cost {
    font-size: 14px;
  }
  .mySubscription__variant {
    width: 265px;
    padding: 17px 22px 12px;
  }
  .mySubscription__btn_done {
    display: block;
    margin: 0 auto 0 0;
    width: 27px;
    height: 27px;
  }
}
@media screen and (max-width: 740px) {
  .mySubscription__title {
    margin: 0 auto 17px;
    font-size: 18px;
  }
  .mySubscription__btn_done {
    margin: 11px 0 9px;
    width: 29px;
    height: 29px;
  }
  .mySubscription__list {
    gap: 12px;
  }
  .mySubscription__variant {
    width: 286px;
    padding: 10px 22px 15px;
  }
  .mySubscription__name {
    font-size: 17px;
    line-height: 25px;
  }
  .mySubscription__name_active {
    color: #0044c9;
  }
  .mySubscription__description {
    margin: 4px 0 13px;
    font-size: 13px;
    line-height: 1;
    color: #606060;
  }
  .mySubscription__description_active {
    color: #111;
  }
  .mySubscription__header {
    margin-top: 10px;
  }
  .mySubscription__cost {
    font-size: 15px;
  }
  .mySubscription__btn {
    height: 44px;
    width: 239px;
  }
  .mySubscription__errorMessage {
    font-size: 16px;
  }
}
