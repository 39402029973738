.section {
  width: calc(100% - 180px);
  max-width: 988px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section__title {
  margin: 25px auto 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: #2f42c2;
}
.nextBtn {
  width: 260px;
  font-size: 18px;
}
.noDocs {
  margin: 20px auto 55px;
  font-size: 18px;
}
.list {
  width: 100%;
  list-style-type: none;
  margin: 26px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.list:nth-child(3) {
  margin-top: 0;
}
.li {
  cursor: pointer;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  position: relative;
  height: 116px;
  margin-bottom: 63px;
}
.li_long {
  height: unset;
}
.li:after {
  position: absolute;
  content: "";
  width: 30px;
  height: 15px;
  background-image: url("../../images/arrow_down.svg");
  background-repeat: no-repeat;
  background-size: contain;
  left: 31px;
  top: 50px;
  cursor: pointer;
}
.li_long:after {
  transform: rotate(180deg);
}
.li .btn,
.li .scanOption,
.li .text,
.li .scannedList {
  display: none;
}
.li_long .btn,
.li_long .scanOption,
.li_long .text,
.li_long .scannedList {
  display: flex;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 231px;
  height: 49px;
  margin: -32px auto 0;
  background-color: #33a3ff;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  position: relative;
}
.btn {
  height: 57px;
  width: 23%;
  font-size: 18px;
}
.btn_disabled{
  background-color: #dbd4f4;
}
.btn_type_print {
}
.btn_type_renew {
  margin-top: 103px;
}
.btn_type_print:before {
  content: url("../../images/print_icon.svg");
  display: block;
  margin-left: 11px;
  height: 18px;
}
.category{
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.category:after{
  content: "";
  position: absolute;
  left: 8%;
  width: 8px;
  height: 14px;
  background: url("../../images/arrow_left_violet.svg") no-repeat;
  background-size: contain;
  transform: rotate(-90deg);
}
.category_active{}
.category_active{
  overflow: visible;
}
.category_active:after{
  transform: rotate(90deg);
}
.variants{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background: #fff;
  border: 1px solid rgba(57, 23, 169, 0.8);
  color: rgba(57, 23, 169, 0.81);
  border-radius: 10px;
  z-index: 1;
}
.variant{
  border-radius: 10px;
  padding: 4px 14px;
  color: #00214e;
  font-size: 17px;
  text-align: right;
}
.variant:hover{
  background-color: #dbd6ef;
}
.scanTitle {
  display: flex;
  margin: 28px 24px 0 0;
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: right;
  color: #00214e;
}
.scanIcon {
  margin-right: 30px;
  display: inline-block;
  width: 32px;
  height: 32px;
  background-image: url("../../images/scan_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.scanOption {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 25px auto 18px;
}
.form{
  display: flex;
  width: 96%;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 2%;
  row-gap: 20px;
}
.input {
  display: none;
}
.scannedList {
  display: flex;
  flex-direction: column;
  width: 84%;
  margin: 25px auto;
  padding: 0;
  list-style-type: none;
  justify-content: center;
  gap: 24px;
}
.scannedLi {
  width: 100%;
  display: grid;
  grid-template-columns: 20px 30% 30% 25%;
  align-items: center;
  gap: 32px;
}
.scannedLi:before {
  content: url("../../images/document_icon.svg");
  width: 16px;
  height: 21px;
}
.label {
  display: flex;
  align-items: center;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  white-space: nowrap;
  color: #626262;
}
.info {
  display: inline-block;
  color: #000;
  margin-right: 3px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text {
  display: flex;
  margin: 25px auto;
  justify-content: center;
}

@media screen and (max-width: 1300px) {
  .section {
    width: calc(100% - 140px);
  }
  .section__title {
    margin: 11px auto 0;
  }
  .img {
    margin-top: 28px;
    height: 114px;
  }
  .noDocs {
    margin: 18px auto 36px;
  }
  .nextBtn {
    width: 220px;
    height: 50px;
    font-size: 17px;
  }
  .list {
    margin-top: 51px;
  }
  .li {
    height: 86px;
    margin-bottom: 52px;
  }
  .li:after {
    width: 22px;
    height: 11px;
    left: 26px;
    top: 37px;
  }
  .li_long {
    height: unset;
  }
  .title {
    font-size: 20px;
  }
  .btn {
    height: 44px;
    font-size: 17px;
    }
  .btn_type_renew {
    margin-top: 80px;
  }
  .scanTitle {
    margin-top: 10px;
    font-size: 20px;
  }
  .scanIcon {
    margin-right: 10px;
    width: 22px;
    background-size: contain;
  }
  .scannedLi {
    grid-template-columns: 20px 30% 30% 25%;
    align-items: center;
    gap: 32px;
  }
  .label {
    font-size: 16px;
  }
  .text {
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) {
  .btn{
    font-size: 15px;
  }
  .category:after{
    left: 5%;
    width: 10px;
    height: 10px;
  }
}
@media screen and (max-width: 900px) {
  .section {
    width: calc(100% - 74px);
    padding-bottom: 20px;
  }
  .scannedLi {
    grid-template-columns: 30% 30% 25%;
    gap: 22px;
  }
  .scannedLi:before {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .section__title {
    margin: 10px auto 0;
    font-size: 18px;
  }
  .img {
    width: 170px;
    height: 142px;
    margin-top: 32px;
  }
  .noDocs {
    font-size: 16px;
  }
  .list {
    margin-top: 36px;
  }
  .li {
    height: 65px;
    margin-bottom: 35px;
  }
  .li_long {
    height: unset;
  }
  .li:after {
    width: 12px;
    height: 6px;
    left: 17px;
    top: 30px;
  }
  .btn {
    width: 190px;
    height: 44px;
  }
  .title {
    width: 144px;
    height: 32px;
    font-size: 16px;
    margin-top: -17px;
  }
  .scanIcon {
    display: none;
  }
  .scanTitle {
    font-size: 16px;
    margin: 5px 22px;
  }
  .scannedLi {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .text {
    margin: 0;
    font-size: 14px;
  }
  .info {
    max-width: 198px;
  }
}

@media screen and (max-width: 600px) {
  .btn {
    margin: 0;
  }
  .btn_type_renew {
    margin-top: 25px;
    border: 1px solid rgba(57, 23, 169, 0.8);
  }
}
