.coupon {
  width: 324px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 0 25px;
  box-sizing: border-box;
}
.coupon__title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #00214e;
  margin: 56px 0 13px auto;
}
.coupon__title_main {
  font-size: 24px;
  font-weight: 600;
  color: #2f42c2;
  margin: 0 auto;
}
.coupon__text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #606060;
  margin: 0 0 0 auto;
  max-width: 299px;
}
.coupon__form {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.coupon__label {
  margin: 7px 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.78;
  color: #121759;
}
.coupon__input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 41px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  font-size: 18px;
  padding: 10px 20px;
}
.coupon__btn {
  margin: 40px auto 0;
  width: 260px;
  height: 57px;
}
.coupon__btn_disabled {
  background-color: #dbd4f4;
}
@media screen and (max-width: 1200px) {
  .coupon {
    width: 282px;
  }
  .coupon__title {
    font-size: 15px;
    margin-top: 13px;
  }
  .coupon__title_main {
    font-size: 24px;
    margin: 0 auto;
  }
  .coupon__text {
    font-size: 15px;
    line-height: 1.1;
  }
  .coupon__label {
    font-size: 15px;
    margin: 0 0 3px;
  }
  .coupon__btn {
    margin-top: 17px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .coupon {
    width: calc(100% - 78px);
    align-items: center;
    padding-bottom: 20px;
  }
  .coupon__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    margin: 0 auto 2px;
  }
  .coupon__title_main {
    margin: 0 auto 24px;
  }
  .coupon__text {
    display: none;
  }
  .coupon__form {
    width: 282px;
  }
  .coupon__label {
    margin: 10px 0 2px;
    font-size: 15px;
    line-height: 2.13;
  }
  .coupon__input {
    width: 100%;
  }
  .coupon__btn {
    margin: 13px auto 0;
  }
}
