.closeBtn {
  width: 16px;
  height: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../images/close_icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 20px 20px 0 auto;
}

@media screen and (max-width: 1200px) {
  .closeBtn {
    width: 12px;
    height: 12px;
  }
}
