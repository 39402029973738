.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 28px 0;
}
.section__title {
  margin: 0 auto 28px;
  color: #2f42c2;
  font-size: 24px;
  font-weight: 600;
}
.card {
  margin: 0 auto;
  width: 340px;
  height: 188px;
  background-color: #121759;
  background-image: url("../../../images/masterCardLogo.svg");
  background-repeat: no-repeat;
  background-position: 30px 30px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
  direction: ltr;
}
.cardNumber {
  margin: 72px auto 50px 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #ffffff;
}
.cardInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardName {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  margin: 0 26px 23px;
}
.form {
  margin-top: 34px;
  display: grid;
  grid-template-rows: repeat(2, 71px);
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'number name'
  'exp cvv';

  column-gap: 98px;
  row-gap: 21px;
}
.number {
  grid-area: number;
}
.name {
  grid-area: name;
}
.exp {
  grid-area: exp;
}
.cvv {
  grid-area: cvv;
}
.label {
  margin-top: 35px;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.73;
  color: #626262;
  width: 100%;
  text-align: right;
  margin-bottom: 6px;
}
.label_small {
  margin: 0;
}
.input {
  box-sizing: border-box;
  padding: 8px 20px;
  text-align: right;
  width: 264px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  height: 34px;
  font-size: 16px;
  line-height: 1.2;
  color: #000000;
}
.input_small {
  width: 124px;
}
.input_date::placeholder {
  font-size: 2px;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.input::placeholder {
  color: #d4d4d4;
}
.popupData {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 30px 41px;
  margin-top: 34px;
  column-gap: 18px;
}
.submitBtn {
  margin: 47px auto 0;
  width: 212px;
  height: 46px;
  font-size: 16px;
}
.submitBtn_disabled {
 background-color: #dbd4f4;
}

@media screen and (max-width: 1400px) {
  .section {
    padding-top: 12px;
  }
  .card {
    width: 264px;
    height: 146px;
    background-position: 20px 24px;
    background-size: 28px 17px;
  }
  .cardNumber {
    margin: 56px auto 35px 24px;
    font-size: 16px;
  }
  .cardName,
  .cardInfo {
    font-size: 14px;
  }
  .form {
    margin-top: 28px;
    grid-template-rows: repeat(2, 61px);
    column-gap: 86px;
    row-gap: 21px;
  }
  .label {
    margin-top: 26px;
    font-size: 14px;
  }
  .input {
    box-sizing: border-box;
    padding: 6px 16px;
    border-radius: 6px;
    width: 237px;
    height: 30px;
    font-size: 15px;
  }
  .input_small {
    width: 112px;
  }
  .popupData {
    margin-top: 25px;
    column-gap: 14px;
  }
  .submitBtn {
    margin-top: 50px;
    width: 190px;
    height: 42px;
  }
}
@media screen and (max-width: 750px) {
  .form {
    grid-template-areas:
      "number number"
      "name name"
      "exp cvv";
    width: 306px;
    row-gap: 38px;
    column-gap: 22px;
  }
  .section {
    padding-top: 8px;
  }
  .section__title {
    font-size: 18px;
    margin-bottom: 11px;
  }
  .card {
    display: none;
  }
  .input {
    width: 100%;
    height: 39px;
    font-size: 19px;
  }
  .label {
    font-size: 17px;
  }
  .submitBtn {
    width: 200px;
    font-size: 16px;
  }
  .input {
    font-size: 14px;
  }
  .input::placeholder {
    font-size: 8px;
  }
  .submitBtn {
    margin-top: 68px;
    width: 260px;
    height: 57px;
  }
}
