.myTreatment {
  display: flex;
  flex-direction: column;
  width: calc(100% - 130px);
  max-width: 1037px;
  align-items: center;
}
.myTreatment__title {
  margin: 25px auto 0;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  color: #2f42c2;
}
.myTreatment__info {
  margin-top: 60px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.65);
  border-radius: 32px;
  box-sizing: border-box;
  padding: 31px 64px;
}
.myTreatment__text {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.25;
  text-align: right;
  color: #626262;
}
.myTreatment__text_small {
  font-size: 18px;
}
.myTreatment__listTitle {
  margin: 50px auto 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #2f42c2;
}
.myTreatment__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 112px;
  row-gap: 50px;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}
.btn{
  margin: 20px 0;
}

@media screen and (max-width: 1439px) {
  .myTreatment {
    width: calc(100% - 108px);
  }
  .myTreatment__list {
    column-gap: 70px;
  }
}
@media screen and (max-width: 1300px) {
  .myTreatment {
    width: calc(100% - 70px);
  }
  .myTreatment__title {
    margin: 11px auto 0;
  }
  .myTreatment__info {
    margin: 40px 0 0;
    padding: 24px 22px;
  }
  .myTreatment__text {
    font-size: 18px;
    line-height: 1.39;
  }
  .myTreatment__text_small {
    font-size: 16px;
  }

  .myTreatment__listTitle {
    margin: 30px auto;
  }
  .myTreatment__list {
    row-gap: 40px;
  }
  .btn{
    width: 290px;
    height: 57px;
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) {
  .myTreatment__text {
    font-size: 16px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 600px) {
  .myTreatment {
    width: calc(100% - 44px);
  }
  .myTreatment__title {
    margin: 12px auto 16px;
    font-size: 18px;
    line-height: 24px;
  }
  .myTreatment__info {
    margin: 0;
    padding: 43px 17px;
  }
  .myTreatment__text_small {
    font-size: 14px;
  }
  .myTreatment__listTitle {
    margin: 16px auto 10px;
    font-size: 18px;
    line-height: 24px;
  }
  .myTreatment__list {
    row-gap: 25px;
  }
}
