.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  direction: rtl;
}
.section__title {
  margin: 0 auto 24px;
  color: #2f42c2;
  font-size: 24px;
  font-weight: 600;
}
.total {
  display: grid;
  grid-template-columns: 215px 215px;
  gap: 80px;
  align-items: center;
}
.total__img {
  width: 100%;
}
.total__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.total__number {
  margin: 0;
  font-size: 70px;
  line-height: 1;
  color: #006cc9;
}
.total__text {
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.2;
}
.achievements {
  list-style-type: none;
  padding: 0;
  margin: 25px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px 65px;
}
.achievementsLi {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background: #fff;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: repeat(3, 100px);
  justify-items: center;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 20px 29px;
}
.achievementsText {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: #111;
}
.achievementsNumber {
  margin: 0;
  width: 86px;
  height: 86px;
  border-radius: 100%;
  border: 1px solid #dbd6ef;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 600;
  color: #006cc9;
}
@media screen and (max-width: 1400px) {
  .section {
    padding: 11px 0 40px;
  }
  .total {
    grid-template-columns: 200px 200px;
  }
}
@media screen and (max-width: 1300px) {
  .section {
    padding-bottom: 30px;
  }
  .total {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
  .total__number {
    font-size: 45px;
  }
  .total__text {
    font-size: 18px;
  }
  .total__img {
    height: 95px;
    width: auto;
    justify-self: flex-end;
  }
  .achievements {
    gap: 20px 40px;
  }
  .achievementsLi {
    padding: 12px 18px;
    gap: 10px;
  }
  .achievementsNumber {
    width: 70px;
    height: 70px;
    font-size: 32px;
  }
  .achievementsText {
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) {
  .total__img {
    height: 70px;
  }
  .total__text {
    font-size: 16px;
  }
  .achievements {
    gap: 18px 35px;
    margin-top: 22px;
  }
  .achievementsLi {
    padding: 8px 12px;
    gap: 4px;
    grid-template-columns: repeat(3, 90px);
  }
  .achievementsText {
    font-size: 15px;
  }
  .achievementsNumber {
    width: 62px;
    height: 62px;
    font-size: 28px;
  }
}
@media screen and (max-width: 800px) {
  .achievements {
    gap: 16px 16px;
    margin-top: 22px;
    width: 97%;
  }
  .achievementsLi {
    padding: 8px 12px;
    gap: 0;
    grid-template-columns: 1.1fr 64px 1fr;
  }
}
@media screen and (max-width: 600px) {
  .section {
    padding: 10px 0 40px;
    width: 315px;
  }
  .section__title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .total {
    gap: 30px;
  }
  .total__img {
    height: unset;
  }
  .total__number {
    font-size: 68px;
  }
  .total__text {
    font-size: 20px;
  }
  .achievements {
    gap: 8px;
    margin-top: 22px;
    width: 97%;
  }
  .achievementsLi {
    grid-template-columns: 1fr;
    grid-template-rows: 34px 84px 1fr;
    gap: 12px;
    align-items: flex-start;
    padding: 22px 12px;
  }
  .achievementsNumber {
    width: 86px;
    height: 86px;
    font-size: 40px;
  }
  .achievementsText {
    font-size: 16px;
    text-align: center;
    line-height: 1.1;
      font-weight: 600;
  }
}
