@import "./Calendar.css";
body {
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layout {
  background: #fbfaff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  background: url("../images/background.jpg") top 20px right no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-position-y: 45px;
}
.modals {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  z-index: 100;
}
.hover:hover {
  transform: translateY(-3px);
  opacity: 0.8;
}
.lock-scroll {
  max-height: 100vh;
  overflow: hidden;
}
html::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
/* Handle */
html::-webkit-scrollbar-thumb {
  opacity: 0.4;
  background: #eae6df;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a19f9f;
}
.scroll_violet::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
/* Handle */
.scroll_violet::-webkit-scrollbar-thumb {
  border-radius: 4px;
  opacity: 0.4;
  background: #2f42c2;
}

/* Handle on hover */
.scroll_violet::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.scroll_violet::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 60px;
}

.button {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  max-width: 290px;
  height: 57px;
  background: #5d3cc7;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.11;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}
.button_white {
  background: #fff;
  border: 1px solid rgba(57, 23, 169, 0.81);
  color: rgba(57, 23, 169, 0.81);
}
input,
button,
textarea {
  font-family: inherit;
}
.error {
  font-size: 16px;
  line-height: 1;
  color: #ff0000;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .error {
    font-size: 14px;
  }
}

