.popup {
  width: 493px;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
  background: #F0F3FF;
}
.popup__title {
  margin: 17px auto 44px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  color: #2F42C2;
}
.popup__text {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.1;
  max-width: 348px;
  text-align: center;
  color: #000000;
}
.popup__account {
  margin: 40px auto 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  color: #121759;
}
.popup__nextBtn {
  margin: 45px auto 40px;
  width: 260px;
  height: 57px;
}
.popup__img{
  height: 230px;
}

@media screen and (max-width: 1400px) {
  .popup {
    width: 385px;
  }
  .popup__title{
    font-size: 25px;
    margin: 12px auto 20px;
  }
  .popup__text {
    font-size: 18px;
    max-width: 68%;
  }
  .popup__account{
    margin: 16px auto 17px;
    font-size: 20px;
  }
  .popup__nextBtn {
    margin-top: 38px;
    width: 203px;
    height: 45px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .popup {
    width: 300px;
  }
  .popup__title{
    font-size: 24px;
    margin-bottom: 10px;
  }
.popup__img{
  height: 190px;
}
  .popup__text {
    font-size: 15px;
  }
  .popup__nextBtn {
    margin-top: 20px;
    width: 200px;
    font-size: 14px;
  }
}
