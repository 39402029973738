.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}
.section_type_terms {
  padding-top: 60px;
}
.section__title {
  font-weight: 600;
  font-size: 24px;
  color: #2f42c2;
  margin: 0 auto 29px;
}
.section__container {
  width: calc(100% - 180px);
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  padding: 50px 38px 24px;
  color: #111;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}
.scrollable {
  max-height: 315px;
  overflow: auto;
  margin-left: -40px;
  padding-left: 40px;
}
.scrollable_not {
  max-height: fit-content;
}
.section__text {
  margin: 12px;
}
.scrollable::-webkit-scrollbar {
  width: 3px;
  background: rgba(231, 242, 253, 0.6);
}
/* Handle */
.scrollable::-webkit-scrollbar-thumb {
  background-color: #8a74d8;
  border-radius: 5px;
}
.scrollable::-webkit-scrollbar:horizontal {
  display: none;
}
/* Handle on hover */
.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #8a74d8;
}
.label {
  display: flex;
  align-items: center;
  gap: 9px;
  margin: 24px 0 37px;
  font-size: 18px;
  font-weight: 600;
  color: #5d3cc7;
}

/*noinspection CssUnknownProperty*/
.input {
  accent-color: #5d3cc7;
  box-sizing: border-box;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 1px;
  box-shadow: 0 0 0 1pt #5d3cc7;
}
.btn {
  margin: 0 auto;
  width: 260px;
  height: 57px;
  font-size: 18px;
}
@media screen and (max-width: 1200px) {
  .section__title {
    margin-bottom: 42px;
  }
  .section__container {
    padding: 24px 27px 16px;
    font-size: 16px;
    font-weight: 400;
  }
  .scrollable {
    max-height: 200px;
    margin-left: -26px;
    padding-left: 26px;
  }
  .scrollable_not {
    max-height: fit-content;
  }
  .label {
    margin: 1px 0 25px;
  }
  .btn {
    width: 203px;
    height: 46px;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .section_type_terms{
    padding: 10px 0;
  }
  .section__title {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .section__container {
    width: calc(100% - 76px);
    padding: 22px 10px 17px;
  }
  .scrollable {
    max-height: 322px;
    margin-left: -8px;
    padding-left: 8px;
  }
  .scrollable_not {
    max-height: fit-content;
  }
  .label {
    font-size: 16px;
    margin: 22px 0 32px;
  }
  .input {
    width: 18px;
    height: 18px;
  }

  .btn {
    width: 260px;
    height: 57px;
    font-size: 18px;
  }
}
