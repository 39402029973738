.popup {
    width: 493px;
    min-height: 539px;
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
}
.popup__title {
    margin: 0 auto 59px;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 1;
    color: #2f42c2;
    text-align: center;
    max-width: 75%;
}
.popup__img {
    width: 145px;
    height: 145px;
    margin: 61px auto 35px;
}
.popup__text {
    max-width: 344px;
    margin: 0 auto;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    text-align: center;
    color: #000000;
}
.popup__btn {
    margin: 0 auto 27px;
    width: 260px;
    height: 57px;
    font-size: 18px;
}
.popup__btn_type_change {
    color: #5d3cc7;
    border: 1px solid #5d3cc7;
    background: transparent;
}
@media screen and (max-width: 1024px) {
    .popup {
        width: 405px;
        min-height: 442px;
    }
    .popup__title {
        margin-bottom: 43px;
        font-size: 20px;
    }
    .popup__img {
        width: 119px;
        height: 117px;
        margin: 57px auto 28px;
    }
    .popup__text {
        max-width: 244px;
        font-size: 18px;
    }
    .popup__btn {
        width: 213px;
        height: 47px;
        font-size: 16px;
    }

}
@media screen and (max-width: 600px) {
    .popup {
        width: 301px;
        min-height: 329px;
    }
    .popup__title {
        font-size: 19px;
        margin-bottom: 32px;
    }
    .popup__text {
        font-size: 15px;
        margin-bottom: 43px;
    }
    .popup__img {
        width: 88px;
        height: 87px;
        margin: 0 auto 20px;
    }
    .popup__btn {
        width: 159px;
        height: 35px;
        font-size: 12px;
        margin-top: 21px;
    }
}
