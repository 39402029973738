.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
}
.section__title {
  margin: 28px auto 44px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1;
  text-align: center;
}
.section__text {
  margin: 0;
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
}
.section__btn {
  margin-top: 48px;
}

@media screen and (max-width: 1300px) {
  .section__btn {
    margin-top: 48px;
    width: 214px;
    height: 45px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .section__title {
    margin: 22px auto 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.1;
    text-align: center;
  }
  .section__text {
    font-size: 16px;
    line-height: 1.1;
  }
}

@media screen and (max-width: 700px) {
  .section {
    width: calc(100% - 48px);
  }
}
