.popup {
  width: 453px;
  min-height: 495px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #111111;
}
.popup__title {
  margin: 0 auto 33px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: #2f42c2;
}
.popup__text {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: right;
}
.popup__text_big {
  margin: 0 auto 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
}
.popup__btn {
  margin: 0 auto 10px;
  width: 260px;
  height: 57px;
  border-radius: 10px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.11;
  text-align: center;
  cursor: pointer;
}
.popup__nextBtn {
  color: #5d3cc7;
  border: 1px solid #5d3cc7;
  background: transparent;
}
.popup__optionsBar {
  height: 0;
  overflow: hidden;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup__optionsBar_visible {
  height: 76px;
}
.popup__options {
  margin: 0 auto;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  list-style-type: none;
  gap: 20px;
}
.popup__optionBtn {
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}
.popup__optionBtn:hover {
  text-decoration: underline;
}
@media screen and (max-width: 1024px) {
  .popup {
    width: 405px;
    min-height: 442px;
  }
  .popup__title {
    margin-bottom: 26px;
    font-size: 20px;
  }
  .popup__text {
    font-size: 16px;
  }
  .popup__text_big {
    margin: 0 auto 38px;
    font-size: 18px;
  }
  .popup__text_big:last-of-type {
    margin-bottom: 17px;
  }
  .popup__nextBtn {
    font-size: 18px;
  }
}
@media screen and (max-width: 560px) {
  .popup {
    width: 301px;
    min-height: 329px;
  }

  .popup__title {
    font-size: 19px;
    margin-bottom: 32px;
  }
  .popup__btn {
    height: 50px;
    width: 190px;
    font-size: 16px;
  }
  .popup__nextBtn {
    margin-top: 21px;
  }
  .popup__optionBtn {
    margin: 0 auto;
  }
  }
