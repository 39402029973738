.arrow {
  position: absolute;
  top: 26px;
  right: 26px;
  cursor: pointer;
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-image: url("../../images/arrow_forward.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 1400px) {
  .arrow {
    width: 19px;
    height: 19px;
    top: 20px;
    right: 18px;
  }
}
