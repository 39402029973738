.urgent {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.13;
  margin: 0 auto 20px;
  display: inline-block;
}

.urgent__link {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
  color: #000000;
  border-bottom: 1px solid #000000;
  transition: all 0.3s;
  cursor: pointer;
}
.urgent__link:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1400px) {
  .urgent {
    font-size: 12px;
    margin: 0 auto 15px;
  }

  .urgent__link {
    font-size: 12px;
  }
}