.carouselWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.carouselItems {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
}
.carouselItem {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s ease;
}
