.section {
  width: 313px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.section__title {
  margin: 60px auto 23px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.78;
  color: #626262;
  text-align: center;
}
.section__img {
  width: 248px;
  height: 200px;
  margin: 60px auto 0;
}
.section__btn {
  width: 290px;
  height: 57px;
  margin: 0 auto;
}
@media screen and (max-width: 1300px) {
  .section__title {
    margin: 41px auto 20px;
    font-size: 16px;
  }
  .section__img {
    width: 190px;
    height: 140px;
    margin: 20px 0 0;
  }
  .section__btn {
    width: 240px;
    height: 48px;
    font-size: 18px;
  }
}
@media screen and (max-width: 800px) {
  .section__img {
    margin-top: 0;
  }
}
@media screen and (max-width: 600px) {
  .section__title {
    margin: 10px auto 44px;
  }
  .section__img {
    margin-top: 44px;
  }
}
