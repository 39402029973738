.popupWindow {
  margin: auto auto;
  box-sizing: border-box;
  position: fixed;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 0 21px -5px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  max-height: 94%;
  overflow-y: auto;
}
.popupWindow::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}
.popupWindow::-webkit-scrollbar-track {
  /*background: transparent;*/
}

/* Handle */
.popupWindow::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
