.section{
  position: relative;
}
.text {
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.11;
  text-align: center;
  color: #000000;
}
.text:first-of-type{
  margin-top: 20px;
}
.calendarSection {
  box-sizing: border-box;
  margin: 56px auto 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 23px;
  justify-content: center;
  background: rgba(255, 255, 255, 0.68);
  border-radius: 32px;
  justify-items: center;
  gap: 30px;
}
.calendar {
  border: none;
  background-color: transparent;
}
.calendar .react-calendar {
  font-size: 40px;
}
.tile {
  border-radius: 100%;
}
.highlight {
  background-image: url("../../images/circle_purple_light.svg");
  background-size: 44px 44px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  padding-top: 12px;
}
.dateContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 70px 0 22px;
}
.timesContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.date {
  margin: 0;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  color: #222b45;
}

.times {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}
.time {
  font-family: inherit;
  font-size: 20px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #bababa;
  border-radius: 4px;
}
.time:hover,
.submitBtn:hover {
  transform: translateY(-3px);
  opacity: 0.8;
}
.time_active {
  justify-content: start;
  padding-right: 16px;
  width: 90px;
  background-color: #5d3cc7;
  color: #fff;
  position: relative;
}
.time_active:before{
  font-family: inherit;
  align-items: center;
  content: "\2715";
  font-size: 11px;
  width: 10px;
  margin-right: 2px;
}
.submitBtn {
  background: #ffffff;
  border: 1px solid #dbd6ef;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
  width: 100%;
  max-width: 290px;
  height: 57px;
  margin: 20px auto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #5d3cc7;
  font-family: inherit;
}

@media screen and (max-width: 1400px) {
  .text:first-of-type{
    margin-top: 12px;
  }
  .calendarSection {
    margin: 5px auto 46px;
    gap: 20px;
  }
  .date {
    font-size: 16px;
  }

  .times {
    gap: 10px;
  }
  .time {
    padding: 13px 9px;
    width: 71px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    direction: ltr;
  }
}
@media screen and (max-width: 1200px) {
  .section{
    max-width: 94%;
  }
}

@media screen and (max-width: 780px) {
  .section {
    background: rgba(255, 255, 255, 0.68);
    border-radius: 16px;
    margin-bottom: 20px;
    max-width: 99%;
  }
  .text:first-of-type{
    margin-top: 24px;
  }
  .calendarSection {
    display: flex;
    flex-direction: column;
    padding: 18px 15px;
    background: transparent;
    margin: 5px auto 10px;
  }
  .tile {
    height: 40px;
  }
  .dateContainer {
    margin-top: 0;
  }
  .text {
    font-size: 16px;
  }
  .submitBtn{
    margin-bottom: 0;
  }
  .highlight {
    background-size: 37px 37px;
    padding-top: 11px;
  }

}
