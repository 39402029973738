.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  direction: rtl;
}
.section__title {
  margin: 0 auto 44px;
  color: #2f42c2;
  font-size: 24px;
  font-weight: 600;
}
.section__title_noMargin {
  margin: 0 auto;
}
.section__subtitle {
  width: fit-content;
  margin: 20px auto;
}
.section__content {
  display: grid;
  width: 90%;
  max-width: 1200px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: flex-start;
}
.progress {
  display: flex;
  color: #111;
  align-items: flex-end;
  position: relative;
  justify-content: center;
}
.progressNumber {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.progressBar {
  position: relative;
}
.progressContent {
  position: absolute;
  top: 33%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.progressContentNumber {
  margin: 0;
  font-size: 60px;
  line-height: 1;
  font-style: normal;
  font-weight: 600;
  color: #5d3cc7;
}
.progressContentText {
  margin: 0;
  font-size: 18px;
  color: #5d3cc7;
  max-width: 105px;
  text-align: center;
  font-weight: 600;
}
.progressContentTag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10100px;
  background: #5d3cc7;
  color: #fff;
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
  margin: 0;
  padding: 5px 16px;
}
.about {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  padding: 32px 28px;
}
.aboutTitle {
  margin: 0;
  color: #0044c9;
  font-size: 20px;
  line-height: 1.5;
}
.aboutText {
  margin: 0;
  color: #606060;
  font-size: 18px;
  line-height: 1.1;
}
.btn {
  outline: none;
  margin: 20px;
  border: none;
  width: fit-content;
  background-color: transparent;
  color: #111;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}
.explanations {
  position: relative;
  width: 80%;
}
.explanationsInfo {
  margin-top: 60px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.65);
  border-radius: 24px;
  box-sizing: border-box;
  padding: 20px 64px;
}
.explanationsList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 20px;
}
.explanationsLi {
  width: 400px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background-color: rgba(255, 255, 255, 0.65);
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  padding: 25px;
}
.explanationsLiHeader {
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 20px;
}
.explanationsLiNumber {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #5d3cc7;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 20px;
}
.explanationsLiHeaderText {
  margin: 0;
  color: #5d3cc7;
  font-size: 20px;
}
.explanationsLiText {
}
@media screen and (max-width: 1400px) {
  .section {
    padding: 11px 0 40px;
  }
  .total {
    grid-template-columns: 200px 200px;
  }
}
@media screen and (max-width: 1300px) {
  .section {
    padding-bottom: 30px;
  }
  .section__title_noMargin {
    margin: 0 auto;
  }
  .explanationsInfo {
    margin-top: 45px;
    border-radius: 10px;
    padding: 13px 25px;
    font-size: 16px;
    line-height: 1.2;
  }
  .explanationsLi {
    width: 250px;
    padding: 20px 15px;
  }
  .explanationsLiHeader {
    grid-template-columns: 25px 1fr;
    gap: 8px;
  }
  .explanationsLiNumber {
    width: 25px;
    height: 25px;
    font-size: 17px;
  }
  .explanationsLiHeaderText {
    font-size: 17px;
  }
  .explanationsLiText {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.2;
  }
}
@media screen and (max-width: 1024px) {
  .section__title {
    margin-bottom: 28px;
  }
  .section__title_noMargin {
    margin: 0 auto;
  }
  .section__content {
    gap: 20px;
  }
  .aboutTitle {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .aboutText {
    font-size: 15px;
    line-height: 1.2;
  }
  .explanations {
    width: 90%;
  }
  .explanationsLi {
    width: 236px;
    padding: 20px 14px;
  }
  .explanationsLiHeaderText{
    font-size: 16px;
  }
  .explanationsLiNumber{
    width: 24px;
    height: 24px;
    font-size: 16px;
  }
  .explanationsLiText{
    font-size: 15px;
  }
}
@media screen and (max-width: 800px) {
  .section__content {
    grid-template-columns: 1fr;
    gap: 80px;
    justify-items: center;
  }
  .about {
    max-width: 65%;
  }
}
@media screen and (max-width: 600px) {
  .section {
    padding: 10px 0 40px;
    width: 100%;
  }
  .section__title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .section__title_noMargin {
    margin: 0 auto;
  }
  .about {
    max-width: unset;
  }
  .explanationsLi{
    width: 100%;
  }
}
