.faq__li {
  width: 100%;
  height: 83px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 28px 40px;
  max-width: 988px;
  background: #ffffff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  position: relative;
  transition: all 0.4s;
}
.faq__li_long {
  height: fit-content;
}
.faq__li:before {
  position: absolute;
  content: "";
  height: 9px;
  width: 9px;
  border-radius: 100%;
  background: #5c41b7;
  right: 16px;
  top: 39px;
}
.faq__li_long:after {
  transform: rotate(180deg);
}
.faq__li:after {
  position: absolute;
  content: "";
  width: 35px;
  height: 17px;
  background-image: url("../../images/arrow_down.svg");
  background-repeat: no-repeat;
  background-size: contain;
  left: 38px;
  top: 39px;
  cursor: pointer;
}
.faq__question {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: right;
  color: #5d3cc7;
}
.faq__questionTitle {
  font-size: 26px;
  line-height: 30px;
  margin: 0;
}
.faq__answer {
  color: #111;
  font-size: 18px;
  line-height: 1.36;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 31px;
}

@media screen and (max-width: 1400px) {
  .faq__li {
    height: 80px;
    padding: 31px 29px 25px;
  }
  .faq__li:before {
    height: 7px;
    width: 7px;
    right: 12px;
  }
  .faq__li:after {
    width: 22px;
    height: 11px;
    left: 26px;
    top: 38px;
  }
  .faq__li_long {
    height: fit-content;
  }
  .faq__title {
    font-size: 28px;
    margin: 65px auto 22px;
  }
  .faq__question {
    font-size: 19px;
  }
  .faq__questionTitle {
    font-size: 19px;
    line-height: 22px;
  }
  .faq__answer {
    margin-top: 25px;
    font-weight: 400;
    font-size: 16px;
  }
}
@media screen and (max-width: 740px) {
  .faq__li {
    padding: 18px 17px;
    height: 65px;
  }
  .faq__li_long {
    height: fit-content;
  }
  .faq__li:before {
    display: none;
  }
  .faq__li:after {
    width: 12px;
    height: 6px;
    left: 17px;
    top: 31px;
  }
  .faq__questionTitle {
    font-size: 16px;
  }
  .faq__answer {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.56;
  }
}
