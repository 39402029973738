.li{
    box-sizing: border-box;
    width: 100%;
    padding: 23px 23px 13px 21px;
    background: #fff;
    box-shadow: 0 3px 27px rgba(0, 0, 0, 0.07);
    border-radius: 14px;
}
.card_answer{
    width: 100%;
    background: #CFC9E233;
    padding: 18px 0 19px;
    box-sizing: border-box;
}
.card__user {
    margin: 15px 0 0;
    display: flex;
    gap: 8px;
}
.card__avatar {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    object-fit: cover;
}
.card__data {
    display: flex;
    flex-direction: column;
}
.card__name {
    margin: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.2;
    text-align: right;
    color: #323232;
}
.card__date {
    margin: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.4;
    text-align: right;
    color: #848484;
}
.card__tag{
    margin: 2px;
    background: #33A3FF;
    border-radius: 100px;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #fff;
    padding: 0 17px;
    box-sizing: border-box;
    height: 13px;
}
.card__text{
    margin: 5px 43px 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.1;
    text-align: right;
    color: #111111;
}
.card__text_answer{
    margin: 0 40px 0 0;
    font-size: 14px;
}

@media screen and (max-width: 1200px) {
    .li{
        padding-top: 10px;
    }
    .card_answer{
        padding: 9px 5px;
    }
    .card__user {
        margin: 10px 0 9px;
        display: flex;
        gap: 8px;
    }
    .card__avatar {
        width: 30px;
        height: 30px;
    }
    .card__text{
        font-size: 14px;
    }
    .card__text_answer{
        margin: 0 40px 0 0;
        font-size: 13px;
    }
}

@media screen and (max-width: 600px) {
    .card__text{
        font-size: 13px;
        margin-left: 0;
    }
    .card__text_answer{
        font-size: 11px;
    }
}