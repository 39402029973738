.section {
  width: 324px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 0 25px;
  box-sizing: border-box;
}
.section__text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #606060;
  margin: 0 0 0 auto;
  max-width: 299px;
}
.form {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form__label {
  margin: 7px 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.78;
  color: #121759;
}
.form__input {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 41px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1;
  padding: 10px 20px;
}
.form__input:after {
  display: block;
  content: "";
  position: absolute;
  top: 17px;
  left: 19px;
  width: 11px;
  height: 6px;
  cursor: pointer;
  background-image: url("../../images/arrow_down.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.form__themesContainer {
  width: 100%;
  position: relative;
}
.form__themes {
  position: absolute;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  top: 100%;
  left: 0;
  width: 100%;
  list-style-type: none;
  margin: 0;
  box-sizing: border-box;
  padding: 12px 18px;
}
.form__theme {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}
.form__btn {
  margin: 35px auto 20px;
}
.form__btn_disabled {
  background-color: #dbd4f4;
}
.subjectsList {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.subjectsLi {
  padding: 0 20px;
  border-radius: 100px;
  background: #fff;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  height: 42px;
  align-items: center;
  color: #121759;
  font-size: 20px;
  box-sizing: border-box;
}
.subjectsLi_active {
  border: 1px solid #5d3cc7;
}
.subjects__checkbox {
  accent-color: #5d3cc7;
  width: 14px;
  height: 14px;
}
@media screen and (max-width: 1300px) {
  .section {
    width: 282px;
  }
  .section__text {
    font-size: 15px;
    line-height: 1.1;
  }
  .form__label {
    font-size: 15px;
    margin: 0 0 3px;
  }
  .form__input:after {
    top: 18px;
    left: 15px;
    height: 38px;
  }
  .subjectsLi, .form__input, .form__btn{
    height: 38px;
    font-size: 17px;
  }
  .form__btn{
    height: 48px;
    font-size: 17px;
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .section {
    width: calc(100% - 34px);
    align-items: center;
    padding-bottom: 20px;
  }
  .section__text {
    display: none;
  }
  .form {
    width: 282px;
  }
  .form__label {
    margin: 10px 0 2px;
    font-size: 15px;
    line-height: 2.13;
  }
  .form__input {
    width: 100%;
  }
  .form__theme {
    font-size: 16px;
  }
}
