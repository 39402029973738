.sidebar {
  position: fixed;
  max-height: 100%;
  top: 0;
  right: 272px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 293px;
  background: rgba(231, 242, 253, 0.6);
  border-left: 1px solid #c9c9c9;
  border-radius: 55px 0 0 0;
  z-index: 4;
  transition: all 0.7s;
}
.sidebar_closed {
  right: 136px;
}
.scrollable {
  overflow-y: auto;
}
.scrollable::-webkit-scrollbar-thumb {
  border-radius: 5px;
  opacity: 0.4;
  background: rgba(231, 242, 253, 0.62);
}
.scrollable::-webkit-scrollbar:horizontal {
  display: none;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #e0daf4;
}
.sidebar__buttons {
  width: calc(100% - 69px);
  display: flex;
  justify-content: space-between;
  margin: 58px auto 52px;
}
.sidebar__btn {
  cursor: pointer;
  outline: none;
  font-family: inherit;
  border: none;
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #545454;
}
.sidebar__btn:hover {
  transform: translateY(-3px);
  opacity: 0.8;
}
.sidebar__btn_active {
  color: #5d3cc7;
}
.sidebar__input {
  box-sizing: border-box;
  width: calc(100% - 28px);
  background-color: transparent;
  margin: 0;
  padding: 9px 15px;
  height: 40px;
  border: 1px solid #5d3cc7;
  border-radius: 15px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22;
}
.sidebar__input::placeholder {
  color: #626262;
}
.sidebar__therapists {
  padding: 0;
  margin: 51px 0 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  direction: rtl;
}
.sidebar__li {
  cursor: pointer;
  width: 100%;
  height: 73px;
  border-bottom: 1px solid #d1d1d1;
  display: grid;
  grid-template-columns: 57px 1fr 32px;
  box-sizing: border-box;
  padding: 13px 15px 10px 10px;
  gap: 16px;
}
.sidebar__li_active {
  background-color: #cee7ff;
}
.sidebar__online {
  position: relative;
}
.sidebar__avatar {
  margin: 0;
  width: 55px;
  height: 55px;
}
.sidebar__online:before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #a9a9a9;
  border-radius: 100%;
  z-index: 2;
  position: absolute;
}
.sidebar__online_active:before {
  background-color: #27e917;
}

.sidebar__therapist {
  display: flex;
  flex-direction: column;
}

/*noinspection CssInvalidPropertyValue*/
.sidebar__name {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #00214e;
  text-wrap: nowrap;
}
.sidebar__therapistInfo {
  display: flex;
  gap: 16px;
}
.sidebar__status {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #626262;
}
.sidebar__tag {
  background-color: #33a3ff;
  border-radius: 10100px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  color: #fff;
  margin: 5px 0 0;
  padding: 2px 10px;
}
.sidebar__unread {
  margin: 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: rgba(57, 23, 169, 0.81);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.56;
  color: #ffffff;
}
@media screen and (max-width: 1300px) {
  .sidebar{
    width: 282px;
  }
  .sidebar__buttons{
    margin: 47px auto 20px;
  }
  .sidebar__therapists{
    margin-top: 28px;
  }
  .sidebar__name{
    font-size: 16px;
  }
  .sidebar__status{
    font-size: 14px;
  }
  .sidebar__unread{
    width: 27px;
    height: 27px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .sidebar {
    right: 258px;
  }
  .sidebar_closed {
    right: 136px;
  }
}
@media screen and (max-width: 1200px) {
  .sidebar {
    width: 266px;
    right: 143px;
  }
  .sidebar_closed {
    right: 102px;
  }
  .sidebar__buttons {
    width: calc(100% - 30px);
  }
  .sidebar__btn {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  .sidebar__input {
    width: calc(100% - 30px);
    padding: 4px 15px;
    height: 31px;
  }
  .sidebar__li {
    grid-template-columns: 55px 1fr 26px;
    box-sizing: border-box;
    padding: 9px 15px;
    gap: 16px;
  }
  .sidebar__name {
    font-size: 15px;
    line-height: 25px;
  }
  .sidebar__therapistInfo {
    align-items: center;
    gap: 5px;
  }
  .sidebar__status {
    font-size: 14px;
    line-height: 25px;
  }
  .sidebar__unread {
    margin: 6px 0 0;
    width: 26px;
    height: 26px;
  }
}
@media screen and (max-width: 750px) {
  .sidebar {
    width: 226px;
  }
  .sidebar__li {
    grid-template-columns: 40px 1fr 18px;
    box-sizing: border-box;
    padding: 9px 15px;
    gap: 10px;
  }
  .sidebar__name {
    font-size: 14px;
  }
  .sidebar__status {
    font-size: 12px;
    line-height: 16px;
  }
  .sidebar__unread {
    margin: 6px 0 0;
    width: 18px;
    height: 18px;
    font-size: 12px;
  }
  .sidebar__avatar {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 600px) {
  .sidebar {
    box-sizing: border-box;
    position: static;
    padding: 49px 0;
    width: calc(100% - 20px);
    height: 98%;
    border-radius: 28px;
    border-left: unset;
    max-width: 450px;
    min-height: unset;
    background: rgba(255, 255, 255, 0.65);
  }
  .sidebar__buttons {
    display: none;
  }
  .sidebar__input {
    display: none;
  }
  .sidebar__therapists {
    margin: 0;
  }
  .sidebar__online:before {
    width: 10px;
    height: 10px;
  }
  .sidebar__li {
    height: 65px;
    padding: 9px 16px;
    gap: 18px;
  }
  .sidebar__li_active {
    background-color: unset;
  }

  .sidebar__name {
    font-size: 16px;
  }
  .sidebar__status {
    font-size: 14px;
    line-height: 18px;
  }
  .sidebar__unread {
    width: 26px;
    height: 26px;
    font-size: 16px;
  }
}
